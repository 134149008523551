@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.__react_component_tooltip.type-error {
  color: #fff;
  background-color: $red;
}
.__react_component_tooltip.type-error.place-left:after {
  border-left-color: $red;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-error.place-bottom:after {
  border-bottom-color: $red;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

.readOnly {
  input {
    color: #C1C6C8;
  }
}
.inputSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: $smartphone) {
    width: 75%;
    min-height: 40px;
  }
  & > textarea {
    width: 100%;
    border: 0;
    outline: none;
    resize: none;
  }
  
  &__mobile {
    margin-bottom: 5px;
  }

  &__field {
    flex: 1;
    border: none;
    padding: 16px 30px;
    @media screen and (max-width: $smartphone) {
      padding: 12px 30px;
    }
    color: $tuatara;
    font-family: Nunito;
    font-size: 18px;
    outline: none;
    width: 100%;
    border-radius: 10px;

    &.campaigns {
      border-radius: 0;
      @media screen and (max-width: $tablet) {
        max-height: 12px;
      }
    }
    &.summary {
      font-family: "Nunito" !important;
      @media screen and (max-width: $tablet) {
        max-height: 12px;
      }
    }
    &.transaction {
      padding: 15px 20px;
    }

    @media screen and (max-width: $smartphone) {
      padding: 14px 20px;
      font-size: 13px;
      width: 75%;
    }
    &__mobile {
      margin-bottom: 0px;
    }
    &-icon {
      padding-right: 25%;
    }
    &-error {
      color: $red;
    }
  }

  &__field_less_padding {
    padding: 20px 5px;

    @media screen and (max-width: $smartphone) {
      padding: 15px 10px;
    }

    &-icon {
      padding-right: 25%;
    }
  }

  :disabled {
    color: #acacac;
  }

  &__show-password {
    width: 26px;
    height: 20px;
    &-button {
      padding: 0px;
      position: relative;
      right: 40px;
      border: 0px;
      background-color: transparent;
    }
    @media screen and (max-width: $smartphone) {
      right: 5px;
    }
  }

  &__hide-password {
    width: 26px;
    height: 20px;
    &-button {
      padding: 0px;
      position: relative;
      right: 40px;
    }
  }

  &__tooltip-logo-container {
    padding: 0 15px 0 10px;

    @media screen and (max-width: $desktop) {
      padding: 0 10px 0 10px;
    }
  }

  &__tooltip_logo {
    width: 20px;
    height: 20px;

    @media screen and (max-width: $desktop) {
      width: 15px;
      height: 15px;
    }
  }
  &__tooltip_logo_select {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    @media screen and (max-width: $desktop) {
      width: 15px;
      height: 15px;
    }
  }
}

.error-tooltip {
  &__back {
    opacity: 1 !important;
  }

  &__container {
    width: 170px;
    max-width: 170px;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    align-items: flex-start;
    @media screen and (max-width: $smartphone) {
      width: 250px;
      align-items: flex-start;
    }
  }

  &__text {
    &--title {
      color: $white;
      font-family: Roboto;
      font-size: 12px !important;
      margin: 2px !important;
      font-weight: 900;
      line-height: 16px !important;
      @media screen and (max-width: $smartphone) {
        width: 100px;
      }
    }
  }
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.bold {
  font-weight: bold !important;
}

.no-padding {
  padding: 0px;
}