@import "../../../styles/breakpoints.scss";

.social-network-container {
  display: flex;
  align-items: center;
  padding: 16px 28px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: $smartphone) {
    width: 100%;
    padding: 16px 34px;
  }

  .social-network-content-container {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    width: 100%;
    padding: 8px 8px;
    background: #ffffff;
    border-radius: 8px;
    justify-content: center;

    @media screen and (max-width: $smartphone) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      row-gap: 36px;
      padding: 16px 24px;
      justify-content: center;
      column-gap: 12px;
      height: fit-content;
    }
    .social-network-logo-container {
      display: flex;
      justify-content: center;
      height: 32px;
      width: 32px;
      margin: 16px;
      @media screen and (max-width: $smartphone) {
        margin: 0px;
        width: 24px;
        height: 24px;
      }
      .social-network-item {
        margin: 0px 12px;
        @media screen and (max-width: $smartphone) {
          margin: 0px;
          width: 24px;
          height: 24px;
        }

        .social-network-image {
          width: 32px;
          height: 32px;

          @media screen and (max-width: $smartphone) {
            width: 24px;
            height: 24px;
          }
        }

        @media screen and (max-width: $smartphone) {
          padding-right: 0px;
        }
      }
    }
  }
}
