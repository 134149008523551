@import "../../styles/breakpoints.scss";

.modal {
  &__container {
    position: fixed;
    z-index: 1000;
  }

  &__container--open {
    overflow: hidden;
    width: 100vw;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.react-router-modal__modal {
  border-radius: 12px;
  background: transparent;
  @media screen and (max-width: 480px) {
    display: flex;
    width: 95%;
    left: 2%;
    top: 4%;
    transform: inherit;
  }
}

.modal__wrapper {
  position: absolute;
  z-index: 1;
}

.modal__modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  border: 1px solid #777;
}

/* mobile devices, particularly iOS, have some quirks to work around */
@media only screen and (max-device-width: 1024px) {
  .modal__container--open {
    position: absolute;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .modal__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
  }
}
