@import "../../styles/breakpoints.scss";
.download-certificate {
  padding-top: 50px;
  &__logo {
    text-align: center;
    width: 100%;
    margin-bottom: 72px;
    &-image {
      width: 190px;
      @media screen and (max-width: $smartphone) {
        width: 130px;
      }
    }
  }
  &__message {
    width: 100%;
    max-width: 616px;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
    font-size: 21px;
    p {
      margin-bottom: 16px;
      font-size: 21px;
      @media screen and (max-width: $smartphone) {
        font-size: 17px;
      }
    }
    @media screen and (max-width: $smartphone) {
      max-width: 290px;
    }
  }
}
