@import "../../../styles/colors";
@import "../../../styles/breakpoints.scss";

.Modal {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 212px;
  margin: 189px auto;
  outline: 0;

  .content-label {
    width: auto;
    float: left;
    max-width: 95%;
  }

  &__label {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 229px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 29.16px 0 24px;
  }

  &__close {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 29px;
    top: 5px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg viewPort='0 0 16,96 16,96' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1' y1='11' x2='11' y2='1' stroke='white' stroke-width='2.61'/%3E%3Cline x1='1' y1='1' x2='11' y2='11' stroke='white' stroke-width='2.61'/%3E%3C/svg%3E");
    background-size: 18;
    background-size: 25px 50px;
    cursor: pointer;
  }

  &__img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    margin-top: 160px;

    .size {
      width: 100%;
      height: 100%;
    }
  }

  &__points {
    text-align: center;
    width: 100%;
    padding: 16px;
    z-index: 20;

    .box {
      display: inline-block;
      position: relative;
    }

    &__point {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 70px;
      display: inline-block;
      cursor: pointer;
    }

    &__point_large {
      position: absolute;
      width: 36px;
      height: 12px;
      border-radius: 24px;
      display: inline-block;
      background: #ffffff;
    }

    .large_left {
      left: 0;
    }

    .large_right {
      right: 0;
    }

    .active {
      background: #ffffff;
    }

    .inactive {
      border: 1px solid #ffffff;
      box-sizing: border-box;
    }
  }

  &__controls {
    position: absolute;
    top: 0px;
    z-index: 40;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &__button {
      pointer-events: all;
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      width: 20px;
      height: 20px;
      text-align: center;
      position: absolute;
      transition: 0.3s ease all;

      &__icon {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 0px;
        background: #f2f2f2;
        border-radius: 46px;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADUSURBVHgBXY89EgFBEIVfDyXlCKokQlwAgZ9wnMAqiQw34AQcYW5gZH4CnACZRJUjSDfRetbumtoOpqqnX7/+Xg5SPT06VaqN8vNxvSBTuZ4OpgBNCGiJCFmRksGWgbdrCLzo69HQF5B7OjqoEegkTSkWtnfWnCMH9xytuSnwPNli0MYtpQ5JCc9MvlZx+wrBdeULGKqYTZE6dPV4SPiYnxBvFg53OoVUoOvfiQcHa2wE2ddB2SXwDs2TYSQQO+3FW+6tWfsM+RAwBXBTaO+SfZGF/ALBgkbjjCm+ngAAAABJRU5ErkJggg==");
        background-repeat: no-repeat;
        background-position: center center;
      }

      .left_icon {
        bottom: 87px;
      }

      .right_icon {
        top: 87px;
      }
    }

    .left {
      transform: rotate(-180deg);
      left: 12px;
    }

    .right {
      right: 12px;
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(56, 60, 93, 0.95);
  z-index: 150;
  width: 100%;
  height: 100%;
}

@media all and (orientation: landscape) {
  .Modal {
    width: 100%;
    height: 100%;
    margin: 0;

    .content-label {
      width: auto;
      float: left;
      max-width: 95%;
      display: none;
    }

    &__label {
      width: 100%;
      bottom: auto;
      padding: 24px 29.16px 0 24px;
      display: block;
    }

    &__close {
      width: 13px;
      height: 13px;
      top: auto;
      margin: -10px -20px 0px;
      float: right;
      position: relative;
      right: auto;
    }

    &__img {
      width: 100%;
      height: 100%;
      margin-top: 0px;
    }

    &__controls {
      &__button {
        .left_icon {
          bottom: 171px;
        }
        .right_icon {
          top: 171px;
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .Modal {
    width: 918px;
    height: 440px;
    margin: 120px auto;

    .content-label {
      width: 874px;
      display: block;
    }

    &__label {
      bottom: 454px;
      font-size: 18px;
      line-height: 25px;
      padding: 0;
    }

    &__points {
      padding: 34px;
    }

    &__controls {
      &__button {
        width: 41px;
        height: 41px;

        &__icon {
          width: 41px;
          height: 41px;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADUSURBVHgBXY89EgFBEIVfDyXlCKokQlwAgZ9wnMAqiQw34AQcYW5gZH4CnACZRJUjSDfRetbumtoOpqqnX7/+Xg5SPT06VaqN8vNxvSBTuZ4OpgBNCGiJCFmRksGWgbdrCLzo69HQF5B7OjqoEegkTSkWtnfWnCMH9xytuSnwPNli0MYtpQ5JCc9MvlZx+wrBdeULGKqYTZE6dPV4SPiYnxBvFg53OoVUoOvfiQcHa2wE2ddB2SXwDs2TYSQQO+3FW+6tWfsM+RAwBXBTaO+SfZGF/ALBgkbjjCm+ngAAAABJRU5ErkJggg==");
          background-position: 55.67% center;
        }

        .left_icon {
          bottom: 187px;
        }

        .right_icon {
          top: 187px;
        }
      }

      .left {
        left: -66px;
      }

      .right {
        right: -66px;
      }
    }
  }
}
