@import "../../styles/breakpoints.scss";

.share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  height: 50px;
  right: 0px;
  bottom: 0px;
  background: #475061;
  border-radius: 8px;
  margin: 30px 30px;

  .sm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 144px;
    height: 24px;
    left: 296px;
    top: 13px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px 0px 34px;

    &__linkedin {
      position: relative;
      width: 24px;
      height: 24px;
      left: 0px;
      top: 0px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px -16px;

      &__vector {
        position: absolute;
        left: 8.33%;
        right: 8.33%;
        top: 8.33%;
        bottom: 8.33%;
        width: 22px;
        height: 22px;
        background-image: url(../../assets/icons/sm-linkedin.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    &__whatsapp {
      position: relative;
      width: 24px;
      height: 24px;
      left: 40px;
      top: 0px;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px -4px;

      &__vector {
        position: absolute;
        left: 10.16%;
        right: 6.25%;
        top: 6.25%;
        bottom: 6.25%;
        width: 22px;
        height: 22px;
        background-image: url(../../assets/icons/sm-whatsapp.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    &__facebook {
      position: relative;
      width: 24px;
      height: 24px;
      left: 80px;
      top: 0px;
      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 0px -20px;

      &__vector {
        position: absolute;
        left: 8.33%;
        right: 8.33%;
        top: 8.33%;
        bottom: 8.33%;
        width: 22px;
        height: 22px;
        background-image: url(../../assets/icons/sm-facebook.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    &__twitter {
      position: relative;
      width: 24px;
      height: 24px;
      left: 120px;
      top: 0px;
      flex: none;
      order: 3;
      flex-grow: 0;
      margin: 0px -4px;

      &__vector {
        position: absolute;
        left: 10.38%;
        right: 6.34%;
        top: 15.24%;
        bottom: 11.83%;
        width: 22px;
        height: 22px;
        background-image: url(../../assets/icons/sm-twitter.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  .labelMobile {
    display: contents;
    position: static;
    width: auto;
    height: 26px;
    right: 261px;
    top: 12px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 162%;
    text-align: right;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 17px;
  }
  .label {
    display: none;
  }
}

@media (min-width: $desktop) {
  .share {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 26px;
    position: static;
    width: 100%;
    height: 50px;
    right: 0px;
    bottom: 0px;
    background: #475061;
    border-radius: 8px;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin: calc(var(--heiht-screen) + 28px) 0px;

    .labelMobile {
      display: none;
    }
    .label {
      display: contents;
      position: static;
      width: 179px;
      height: 26px;
      right: 261px;
      top: 12px;
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 162%;
      text-align: right;
      color: #ffffff;
      
    }
  }
}
