@import "../../styles/breakpoints.scss";

.codeInput {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  &__InputContainer {

    input {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      border: 1px solid #D4D7D9;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      font-family: Nunito;
      color: #475061;
      outline: none;
      @media screen and (max-width: $smartphone) {
        width: 32px;
        height: 32px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input::placeholder {
      text-align: center;
      line-height: 100px;
      color: #E4E6E7;
    }

    input:focus {
      border-color: #0BBEF0;
    }

    .attempt-2, .attempt-3 {
      color: #FF7D00 !important;
    }

    .attempt-4 {
      color: #FF317B !important;
    }

    .timeUp {
      color: #FF317B !important;
    }     
  }
  
}