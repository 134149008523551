@import "./breakpoints.scss";

.title {
  font-family: "Nunito", sans-serif;
  color: $tuatara;
  font-weight: 800;
  font-size: 36px;
  line-height: 25px;
  @media screen and (max-width: $smartphone) {
    font-size: 24px;
    line-height: 15px;
  }
  &--blue {
    font-family: "Nunito", sans-serif;
    color: $congress-blue;
    font-weight: 800;
    font-size: 36px;
    line-height: 25px;
    @media screen and (max-width: $smartphone) {
      font-size: 26px;
      line-height: 15px;
    }
  }
  &--turquoise {
    font-family: "Nunito", sans-serif;
    color: $bright-turquoise;
    font-weight: 800;
    font-size: 36px;
    line-height: 25px;
    @media screen and (max-width: $smartphone) {
      font-size: 26px;
      line-height: 15px;
    }
  }
  &--white {
    font-family: "Nunito", sans-serif;
    color: $white;
    font-weight: 800;
    font-size: 36px;
    line-height: 25px;
  }
}

.subtitle {
  font-family: "Nunito", sans-serif;
  color: $tuatara;
  font-weight: 800;
  font-size: 23px;
  line-height: 15px;
  &--blue {
    color: $bright-turquoise;
    font-family: "Nunito", sans-serif;
    font-weight: 800;
    font-size: 23px;
    line-height: 15px;
  }
  &--congress-blue {
    color: $congress-blue;
    font-family: "Nunito", sans-serif;
    font-weight: 800;
    font-size: 23px;
    line-height: 22px;
    display: flex;
    align-self: center;
  }
  &--white {
    color: $white;
    font-family: "Nunito", sans-serif;
    font-weight: 800;
    font-size: 23px;
    line-height: 15px;
  }
  &--turquoise-roboto {
    color: $bright-turquoise;
    font-family: "Roboto";
    font-weight: "800";
    font-size: 23px;
    line-height: 15px;
  }
  &--small {
    font-family: "Nunito", sans-serif;
    color: $tuatara;
    font-weight: 800;
    font-size: 15px;
    line-height: 15px;
  }
}

.card-title {
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: $tuatara;
  font-weight: 800;
  max-height: 44px;
  overflow: hidden;
}

.small-text {
  font-family: "Nunito", sans-serif;
  line-height: 15px;
  font-size: 15px;
  color: $tuatara;
}

.no-bold {
  font-weight: 400;
}

.bold {
  font-weight: 800;
}

@media (max-width: 570px) {
  .subtitle {
    &--turquoise-roboto {
      font-size: 17px;
    }
  }
}

.title-roboto-turquoise-20 {
  color: $bright-turquoise;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: "800";
  line-height: 15px;
}

.description-roboto-tuatara-17 {
  color: $tuatara;
  font-family: "Roboto";
  font-size: 17px;
  font-weight: "800";
  line-height: 19px;
  white-space: pre-line;
}

.description-roboto-tuatara-12 {
  color: $description-gray;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: "800";
  line-height: 16px;
}

@media screen and (max-width: 500px) {
  .title-roboto-turquoise-20 {
    font-size: 17px;
  }

  .description-roboto-tuatara-17 {
    font-size: 14px;
    white-space: pre-line;
  }
}

.description {
  font-family: "Roboto", sans-serif;
  color: $tuatara;
  font-size: 20px;
  line-height: 30px;
  @media screen and (max-width: $smartphone) {
    font-size: 18px;
    line-height: 22px;
  }
}
