@import "../../styles/breakpoints.scss";

.paginator {
  max-width: 1110px;
  margin-bottom: 32px;

  #drop-down-paginator {
    background: #f2f2f3;
    border-radius: 4px;
    width: 64px;
    height: 40px;
    margin: 0px 8px;
    padding-left: 8px;
  }

  #drop-down-paginator-disabled {
    background: #f2f2f3;
    border-radius: 4px;
    color: #d4d7d9 !important;
    width: 64px;
    height: 40px;
    margin: 0px 8px;
    padding-left: 8px;
  }

  .disabled_invest_paginator {
    color: #d4d7d9 !important;
  }

  &__container {
    margin: 0 0px 0 auto;
    font-family: Nunito;
    font-size: 16px;
    color: #475061;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    max-width: 500px;
    height: 64px;
    padding: 12px;
    margin-top: 32px;
  }

  &__controls {
    display: flex;
    align-items: center;
    margin-left: 8px;

    span {
      background: #f2f2f3;
      border-radius: 4px;
      width: 79px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      margin: 0px 8px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__info > div {
    position: relative;
    div > div {
      margin: 0px 8px;
      border-radius: 5px;
      width: 64px;
      overflow-y: hidden;

      ul > li {
        justify-content: center;
        padding-left: 0;
        font-family: "Nunito";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: #ffffff;

        &:hover {
          font-weight: 700;
          background: #f2f2f3;
          border-radius: 4px 4px 0px 0px;
        }

        &:first-child {
          box-shadow: none !important;
          border-radius: 4px 4px 0px 0px !important;
        }

        &:last-child {
          box-shadow: none !important;
          border-radius: 0px 0px 4px 4px !important;
        }
      }
    }
  }

  &__icon-left,
  &__icon-right {
    background: #f2f2f3;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: 8px;
  }

  &__icon-right > img {
    transform: rotate(-180deg);
  }
}

@media screen and (max-width: $smartphone) {
  .paginator {
    &__container {
      flex-direction: column;
      align-items: flex-end;
      height: 128px;
      justify-content: space-evenly;
      padding: 16px;
      min-width: 320px;
    }
  }
}

@media (min-width: $desktop) {
  .paginator {
    width: 90%;
    margin: 0px auto 40px;
    display: flex;
    justify-content: flex-end;
  }
}
