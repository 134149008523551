@import "../../../styles/breakpoints.scss";
@import "../../../styles/colors.scss";

.bullet-presentation-container {
  padding: 16px 28px 32px 28px;

  .bullet-presentation-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 31px;
    width: 100%;

    @media screen and (max-width: $smartphone) {
      display: flex;
      flex-direction: column;
    }

    .bullet-presentation-item {
      margin-bottom: 24px;
      &:last-child{
        margin-bottom: 0px;
      }
      @media screen and (max-width: $smartphone) {
        margin-bottom: 16px;

      }

      &:last-child{
        margin-bottom: 0px;
      }

      &__list {
        margin-top: 8px;
      }

      &__title {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: $font-purple-gray;
      }
      &__content {
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 162%;
        color: $font-purple-gray;
      }
      &__item{
        margin-left: 24px;
        margin-bottom: 8px;

        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
}
