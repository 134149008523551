@import "../../../styles/breakpoints.scss";

.simple-text {
  display: flex;
  flex-direction: row;
  padding: 16px 28px 32px;
  background: #f2f2f2;
  margin: 0 auto;
  column-gap: 48px;

  @media screen and (max-width: $smartphone) {
    width: 100%;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 0;
  }
  &__column {
    width: 50%;
    &:only-child {
      width: 100%;
    }
    @media screen and (max-width: $smartphone) {
      width: 100%;

      &:first-child {
        margin-bottom: 18px;
      }
    }
  }
  &__container {
    width: 100%;
  }
  &__content {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 162%;
    color: #5d5a6a;
  }

  
}
