@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.tooltip_text_carousel{
  color: #ffffff !important;
  opacity: 0.84 !important;
  width: auto !important;
  max-width: 300px !important;
  background: #383C5D !important;
  border-radius: 8px !important;
}

.tooltip_text_carousel::after{
  display: none !important;
}

.carousel {
  position: relative;
  overflow: hidden;

  &__slides_how {
    display: flex;
    flex-wrap: nowrap;

    &__slide {
      min-width: 100%;
      overflow: hidden;
      transition: 0.3s ease all;
      z-index: 10;
      position: relative;

      &__img {
        width: 100%;
        height: 212px;
        vertical-align: top;
        cursor: pointer;

        .size {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -100;
        }
      }
    }
  }

  &__points {
    text-align: center;
    position: absolute;
    top: 136px;
    width: 100%;
    background: linear-gradient(0deg, #282828 0%, rgba(40, 40, 40, 0) 90.94%);
    height: 80px;
    padding: 25px;
    z-index: 20;

    .box {
      display: inline-block;
      position: relative;
    }

    &__point {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 70px;
      display: inline-block;
    }

    &__point_large {
      position: absolute;
      width: 36px;
      height: 12px;
      border-radius: 24px;
      display: inline-block;
      background: #ffffff;
    }

    .large_left {
      left: 0;
    }

    .large_right {
      right: 0;
    }

    .active {
      background: #ffffff;
    }

    .inactive {
      border: 1px solid #ffffff;
      box-sizing: border-box;
    }
  }

  &__texto {
    width: 100%;
    left: 380px;
    top: 212px;
    background: #282828;
    z-index: 21;

    &__img {
      position: static;
      width: 52px;
      height: 52px;
      left: 0px;
      top: 0px;
      float: left;
      margin: 16px 0 0 20px;
      background: #ffffff;
      border-radius: 70px;

      img {
        width: 52px;
        height: 52px;
        border-radius: 50%;
      }
    }

    &__info {
      float: left;
      align-items: center;
      color: #ffffff;
      font-family: Nunito;
      font-style: normal;
      margin: 16px 0 0 23px;

      &__company_index {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }

      &__company {
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        margin: 4px 0 0 0;
        height: 24px;
        width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    &__description {
      float: left;
      align-items: center;
      color: #ffffff;
      font-family: Nunito;
      font-style: normal;
      margin: 20px 20px 0 20px;

      &__campaign_index {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }

      &__campaign {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 4px 0 0 0;
        height: auto;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    &__button {
      margin: 0 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0 25px 0;

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        position: static;
        width: 219px;
        height: 48px;
        right: 58px;
        top: 152px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #ffffff;
        mix-blend-mode: normal;
        border-radius: 100px;
        cursor: pointer;
      }

      .action {
        background: #7d7bfa;
        border: #7d7bfa;
      }

      .note {
        background: #496cff;
        border: #496cff;
      }

      .debt {
        background: #02bac6;
        border: #02bac6;
      }
    }
    .without {
      padding: 15px 0 10px 0;
      button {
        width: 0;
        height: 0;
      }
    }
  }

  &__controls {
    position: absolute;
    top: 0;
    z-index: 40;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &__button {
      pointer-events: all;
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      width: 50px;
      height: 41px;
      text-align: center;
      position: absolute;
      transition: 0.3s ease all;

      &__icon {
        position: absolute;
        width: 32px;
        height: 32px;
        background: #f2f2f2;
        border-radius: 46px;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADUSURBVHgBXY89EgFBEIVfDyXlCKokQlwAgZ9wnMAqiQw34AQcYW5gZH4CnACZRJUjSDfRetbumtoOpqqnX7/+Xg5SPT06VaqN8vNxvSBTuZ4OpgBNCGiJCFmRksGWgbdrCLzo69HQF5B7OjqoEegkTSkWtnfWnCMH9xytuSnwPNli0MYtpQ5JCc9MvlZx+wrBdeULGKqYTZE6dPV4SPiYnxBvFg53OoVUoOvfiQcHa2wE2ddB2SXwDs2TYSQQO+3FW+6tWfsM+RAwBXBTaO+SfZGF/ALBgkbjjCm+ngAAAABJRU5ErkJggg==");
        background-repeat: no-repeat;
        background-position: 55.67% center;
      }

      .left_icon {
        bottom: 88px;
      }

      .right_icon {
        top: 88px;
      }
    }

    .left {
      transform: rotate(-180deg);
      left: 0;
    }

    .right {
      right: 0;
    }
  }
}

@media (min-width: $desktop) {

  .tooltip_text_carousel{
    max-width: none !important;
  }

  .center_carousel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel {
    width: 1110px;
    height: 406px;
    border-top-left-radius: 12px 12px;
    border-top-right-radius: 12px 12px;

    &__slides_how {
      &__slide {
        &__img {
          height: 354px;

          .size {
            border-top-left-radius: 12px 12px;
            border-top-right-radius: 12px 12px;
          }
        }
      }
    }

    &__points {
      top: 213px;
      height: 120px;
      padding: 44px;
    }

    &__texto {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      margin-top: -24px;
      top: 330px;
      position: absolute;
      left: 0;
      border-bottom-left-radius: 12px 12px;
      border-bottom-right-radius: 12px 12px;

      &__img {
        margin: 0 24px 0 33px;
      }

      &__info {
        width: 240px;
        margin: 14px 0 18px 0;
        height: auto;

        &__company_index {
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          width: 240px;
        }

        &__company {
          font-weight: 800;
          font-size: 18px;
          margin: 4px 0 0 0;
          line-height: 24px;
          width: 240px;
          -webkit-line-clamp: 2;
          height: auto;
        }
      }

      &__description {
        width: 445px;
        margin: 14px 0 18px 32px;
        height: auto;

        &__campaign_index {
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
        }

        &__campaign {
          font-weight: 800;
          font-size: 18px;
          margin: 4px 0 0 0;
          line-height: 24px;
          width: auto;
          height: auto;
        }
      }

      &__button {
        width: 240px;
        margin: 28px 32px 24px 32px;
        width: 220px;
        height: 48px;
        padding: 0;
        button {
          padding: 12px 35px;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .without {
        margin: 0;
        width: 0;
        padding: 0;
        button {
          width: 0;
          margin: 0;
        }
      }

      .countdown{
        height: auto;
        margin: 14px 32px 18px 32px;
      }
    }

    &__controls {
      &__button {
        &__icon {
          position: absolute;
          width: 40px;
          height: 40px;
          background: #f2f2f2;
          border-radius: 46px;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADUSURBVHgBXY89EgFBEIVfDyXlCKokQlwAgZ9wnMAqiQw34AQcYW5gZH4CnACZRJUjSDfRetbumtoOpqqnX7/+Xg5SPT06VaqN8vNxvSBTuZ4OpgBNCGiJCFmRksGWgbdrCLzo69HQF5B7OjqoEegkTSkWtnfWnCMH9xytuSnwPNli0MYtpQ5JCc9MvlZx+wrBdeULGKqYTZE6dPV4SPiYnxBvFg53OoVUoOvfiQcHa2wE2ddB2SXwDs2TYSQQO+3FW+6tWfsM+RAwBXBTaO+SfZGF/ALBgkbjjCm+ngAAAABJRU5ErkJggg==");
          background-repeat: no-repeat;
          background-position: 55.67% center;
          padding: 8px 8px;
        }

        .left_icon {
          bottom: 158px;
        }

        .right_icon {
          top: 158px;
        }
      }

      .left {
        left: 32px;
      }

      .right {
        right: 32px;
      }
    }
  }
}
