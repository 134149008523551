@import "../../../styles/breakpoints.scss";

.info_banner {
  background: url("../../../assets/images/mobile-bgnd-info-banner.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 355px;
  width: 100%;
  max-width: 424px;
  border-radius: 8px;

  &__container {
    height: 400px;
    width: 100%;
    padding: 0px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 43.5px 32px;
  }

  &__text_content {
    margin: 24px 0px;
    width: 256px;
  }

  &__question {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;   
    color: #FFFFFF;
    margin: 8px 0px;
    text-align: center;
    display: table-cell;
  }

  &__text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 162.9%;
    color: #FFFFFF;
    margin: 8px 0px;
    text-align: center;
  }
} 

@media (min-width: $responsive-desktop) {
  .info_banner {
    background: url("../../../assets/images/bgnd-info-banner.png");
    background-repeat: no-repeat;
    background-position: center center;
    max-width: 1110px;
    height: 224px;

    &__container {
      height: 300px;
      padding: 0px 128px 0px 128px;
    }

    &__content {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      padding: 60px 318px 60px 318px;
    }

    &__icon {
      margin: 0px 12px;
    }

    &__text_content {
      margin: 0px 12px;
      width: auto;
    }

    &__question {
      display: contents;
    }

    &__text {
      width: 352px;
      text-align: initial;
    }
  }
}