@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.__react_component_tooltip.type-dark.place-top.available__tooltipColor:after {
  border-top-color: #685e90 !important;
}
.__react_component_tooltip.place-top:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -8px;
}

.balance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px 32px;
  width: 100%;
  height: 1027px;
  background: #ffffff;
  border-radius: 0px 100px 0px 0px;
}

.balance-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 27px 0px 28px;
  position: static;
  width: 100%;
  height: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 120px;
    height: 40px;
    background: #f2f2f2;
    border-radius: 48px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: none;
    cursor: pointer;
  }
}

.balance-content-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 76px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 22px;

  p {
    position: static;
    width: 48px;
    height: 24px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #475061;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
  }

  &__icon {
    margin: 0px 8px;
  }
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 899px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 32px 0px;
}

.title-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  width: 100%;
  height: 92px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.title-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: static;
  width: 100%;
  height: 24px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #475061;
  border-left: 4px solid #0bbef0;
  padding-left: 10px;
}

.description-subtitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 0px 12px;
  position: static;
  width: 100%;
  height: 56px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px 24px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475061;
}

.balance-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 783px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px 0px;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 677px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 16px 16px;
  position: static;
  width: 100%;
  height: 264px;
  background: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.line {
  position: static;
  width: 100%;
  height: 1px;
  background: #e6e6e6;
  mix-blend-mode: normal;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.amount-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  width: 100%;
  height: 412px;
  background: #f2f2f2;
  border-radius: 0px 0px 8px 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.amount-cop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 60px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  &__label {
    position: static;
    width: 100%;
    height: 24px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #0bbef0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px 4px;
  }

  h2 {
    position: static;
    width: 100%;
    height: 32px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #0bbef0;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.amount-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 148px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px 0px;
}

.available {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 64px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-right: 56px;
  margin-bottom: 24px;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 100%;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px 16px;

    p {
      position: static;
      height: 24px;
      font-family: Nunito;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      color: #475061;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 32px 0px 0px;
    }

    img {
      position: static;
      width: 24px;
      height: 24px;
      left: 126px;
      top: 0px;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  &__tooltipColor {
    background-color: #685e90 !important;
    opacity: 1 !important;
    border-radius: 8px;
  }

  &__label {
    font-family: Nunito;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #f9f9f9;
  }
  > p {
    position: static;
    width: 100%;
    height: 24px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #475061;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.confirmed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 64px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-right: 56px;

  h2 {
    position: static;
    width: 100%;
    height: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #475061;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px 16px;
  }

  p {
    position: static;
    width: 100%;
    height: 24px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #475061;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.investments-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 0px;
  position: static;
  width: 100%;
  height: 105px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px 0px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 16px;
    position: static;
    width: 100%;
    height: 64px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px 24px;

    h2 {
      position: static;
      width: 100%;
      height: 24px;
      font-family: Nunito;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      color: #475061;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 0px 16px;
    }

    p {
      position: static;
      width: 100%;
      height: 24px;
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #475061;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
}

.investments-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  height: 275px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 0px 32px;
    position: static;
    width: 100%;
    height: 84px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 16px 0px 8px;

    &__data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      position: static;
      width: 100%;
      height: 84px;
      flex: none;
      order: 0;
      flex-grow: 0;

      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        position: static;
        width: 100%;
        height: 44px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-bottom: 16px;

        h2 {
          position: static;
          width: 100%;
          height: 20px;
          font-family: Nunito;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #475061;
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        p {
          position: static;
          width: 100%;
          height: 24px;
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 24px;
          color: #475061;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }

      > p {
        position: static;
        width: 100%;
        height: 24px;
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #475061;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }

  &__item-confirmed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 32px;
    position: static;
    width: 100%;
    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px 13px;

    :nth-child(1) {
      position: static;
      width: 100%;
      height: 15px;
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #475061;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-bottom: 16px;
    }
    :nth-child(2) {
      position: static;
      width: 100%;
      height: 20px;
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #475061;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
}

.action-balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: static;
  width: 100%;
  height: 74px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 32px 0px 0px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: static;
    width: 200px;
    height: 74px;
    flex: none;
    order: 0;
    flex-grow: 0;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
      position: static;
      width: 200px;
      height: 40px;
      background: #0bbef0;
      mix-blend-mode: normal;
      border-radius: 48px;
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      flex: none;
      order: 0;
      flex-grow: 0;
      cursor: pointer;
    }

    p {
      position: static;
      width: 132px;
      height: 22px;
      left: 34px;
      top: 52px;
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-decoration-line: underline;
      color: #475061;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 12px 0px;
      cursor: pointer;
    }
  }
}

@media (min-width: $desktop) {
  
  .balance-container {
    align-items: center;
    padding: 24px 0px 56px;
    height: 627px;
  }

  .balance-content {
    padding: 0px;
    margin: 0 auto;
    width: 91%;
    max-width: 1110px;
  }

  .balance {
    height: 483px;
  }

  .title-info {
    height: 68px;
  }

  .title-balance {
    height: 32px;
    font-size: 24px;
    line-height: 32px;
  }

  .description-subtitle {
    height: 24px;
    font-size: 18px;
    line-height: 24px;
  }

  .balance-data {
    height: 391px;
    border-radius: 8px;
  }

  .data {
    height: 391px;
    #line-data {
      position: static;
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      mix-blend-mode: normal;
      border-radius: 4px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: 0px;
    }
  }

  .amount {
    height: 185px;
    flex-direction: row;
    align-items: center;
    padding: 24px 0px 16px 32px;
    border-radius: 12px 12px 0px 0px;
  }

  .amount-cop {
    width: 539px;
    height: 40px;
    flex-wrap: wrap;
    flex-direction: row;

    &__label {
      width: 52px;
      height: 32px;
      font-size: 24px;
      line-height: 32px;
      margin: 0px 8px 0px 0px;
    }

    h2 {
      width: 415px;
      height: 40px;
      font-size: 40px;
      line-height: 100%;
    }
  }

  .amount-item {
    width: 297px;
    height: 128px;
    margin-top: 8px;
  }

  .available {
    margin-bottom: 12px;
    &__title {
      width: 150px;
      height: 24px;
      margin: 0px 0px 12px;

      p {
        width: 102px;
        height: 24px;
      }
    }

    &__tooltipColor {
      width: 216px;
      height: 88px;
      padding: 12px 12px;
    }

    &__label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    > p {
      width: 297px;
      height: 32px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .confirmed {
    width: 245px;
    height: 48px;

    h2 {
      width: 87px;
      margin: 0px 0px 4px;
    }

    p {
      width: 245px;
    }
  }

  .amount-data {
    padding: 16px 0px 24px 32px;
    height: 205px;
    border-radius: 0px 0px 12px 12px;
  }

  .investments-title {
    width: 318px;
    height: 68px;
    margin: 0px;
    padding: 0px;

    &__item {
      padding: 0px;
      height: 68px;

      h2 {
        width: 160px;
        margin: 0px 0px 12px;
      }

      p {
        width: 318px;
        height: 32px;
        line-height: 32px;
        font-size: 24px;
        font-weight: 700;
        margin: 0px 0px 24px 0px;
      }
    }

    #line-investments-title {
      visibility: hidden;
    }
  }

  .investments-data {
    flex-direction: row;
    width: 1078px;
    height: 73px;
    margin-top: 24px;

    &__item {
      padding: 0px;
      height: 73px;
      width: 245px;
      margin-top: 0px;

      &__data {
        width: 259px;
        height: 73px;

        &__title {
          width: 100%;
          height: 45px;
          margin-bottom: 4px;
        }
      }
    }

    &__item-confirmed {
      justify-content: space-between;
      position: static;
      width: 245px;
      height: 73px;
      margin: 0px;
      padding: 0px;

      :nth-child(1) {
        width: 162px;
        height: 20px;
        margin-bottom: 29px;
      }

      :nth-child(2) {
        width: 100%;
        height: 24px;
      }
    }
  }

  .line {
    order: 0;
    width: 1px;
    height: 73px;
    margin: 0px 40px;
  }

  .action-balance {
    z-index: 30;
    padding: 0px 32px;
    width: 264px;
    height: 73px;
    top: 324px;
    position: absolute;
    margin: 32px 0px 16px 137px;
    &__container {
      button {
        background: #0bbef0;
      }
    }
  }
}
