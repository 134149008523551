@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.Modal_document {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 700px;
  margin: 30px auto;
  outline: 0;
  display: flex;
  justify-content: center;
}

.Overlay_document {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(56, 60, 93, 0.95);
  z-index: 150;
  width: 100%;
}

.title_document {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 90%;
  height: 28px;
  top: 30px;
  margin: 0px 27px;
}

.title_document img {
  position: static;
  width: 28px;
  height: 28px;
  left: 292px;
  top: 0px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
}

.title_document p {
  position: static;
  height: 25px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.content_document {
  position: absolute;
  width: 90%;
  height: 496px;
  top: 51px;
  background: #ffffff;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 13.5px auto;
}

.docs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 19px 40px 19px;
  position: absolute;
  width: 100%;
  height: 416px;
  left: 0;
  top: 19px;
  overflow-y: auto;
  border-bottom: 1px solid #cdcdcd;
}

.doc-item {
  display: flex;
  align-items: center;
  padding: 0px;
  position: static;
  width: 100%;
  height: 110px;
  left: 0px;
  top: 380px;
  flex: none;
  order: 10;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px dashed #cdcdcd;
  align-content: space-between;
}

.doc-item a {
  cursor: pointer;
}

.doc-item img {
  position: static;
  width: 32px;
  height: 32px;
  left: 245px;
  top: 0px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: auto;
  max-width: 80%;
  height: auto;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 10px;
}

.frame p:nth-child(1) {
  position: static;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #475061;
  margin: 0px 0px 5px 0px;
}

.frame p:nth-child(2) {
  position: static;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #475061;
}

.button_document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  position: absolute;
  width: 320px;
  height: 61px;
  left: 0px;
  top: 435px;
}

.Filled {
  display: none; //flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  position: static;
  width: 206px;
  height: 38px;
  left: 57px;
  top: 11.5px;
  background: #02bac6;
  mix-blend-mode: normal;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  border: none;
}

.content-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 170px;
  height: 0px;
  left: 30px;
  top: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}

.content-button p {
  position: static;
  height: 22px;
  left: 0%;
  right: 16.44%;
  top: calc(50% - 22px / 2);
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.content-button img {
  position: static;
  width: 30px;
  height: 30px;
  left: 126px;
  top: 1px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 4px;
}

.title_item_document {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: auto;
  width: 100%;
}

.tooltip_documents{
  color: #ffffff !important;
  opacity: 0.84 !important;
  width: auto !important;
  max-width: 70% !important;
  background: #383C5D !important;
  border-radius: 8px !important;
}

.tooltip_documents::after{
  display: none !important;
}

@media (min-width: $desktop) {
  .tooltip_documents{
    max-width: 450px !important;
  }
  
  .Modal_document {
    width: 590px;
    height: 620px;
    margin: 50px auto;
  }

  .title_document {
    width: 538px;
    height: 28px;
    top: 23px;
    margin: 0px 27px;
  }

  .content_document {
    width: 538px;
    height: 496px;
    top: 45px;
  }

  .docs {
    width: 538px;
    height: 413px;
    left: 0;
    top: 22px;
    padding: 0 0 0 28px;
  }

  .docs::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
  }
  .docs::-webkit-scrollbar-corner {
    background-color: transparent;
    border-color: transparent;
  }
  .docs::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: grey;
  }
  .docs::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  .doc-item {
    width: 482px;
    height: 95px;
  }

  .doc-item img {
    left: 450px;
    top: 6px;
  }

  .button {
    width: 510px;
    left: 15px;
  }

  .frame {
    height: auto;
    width: 400px;
    max-width: 100%;
  }

  .frame p:nth-child(1) {
    margin: 0px 0px;
  }

  .Filled {
    left: 166px;
    top: 447px;
  }

  .content-button {
    width: 170px;
    height: 5px;
  }

  .content-button img {
    left: 0px;
  }
}
