@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.keywordFilter-container {
  width: 100%;
  position: relative;
  margin-top: 20px;

  input {
    border-radius: 5px;
    padding: 14px 15px 14px 20px;
    font-family: Nunito, sans-serif;
    color: rgba(0, 0, 0, 0.38);
    background-color: #ffffff;
    box-shadow: 0px 8px 16px -15px #e0e2e4;
    border-radius: 5px;
    font-size: 16px !important;
    width: 100%;
    border: none;
    outline: none;
  }

  input::placeholder {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7c878e;
  }

  .inactive_input_filter{
    opacity: 0.4;
  }

  .input-icon {
    padding: 15px;
    position: absolute;
    font-size: calc(20px + 0.2em);
    right: 0px;
    color: rgb(186, 189, 202);
    top: 0px;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-family: Nunito, sans-serif;
  }
  .image_icon {
    background-image: url("../../assets/images/SearchInvest.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 18px;
    height: 18px;
  }

  .image_icon_disabled {
    background-image: url("../../assets/images/SearchInvestDisabled.png") !important;
  }
}

@media (min-width: $desktop) {
  .keywordFilter-container {
    width: 79.7%;
    height: 48px;
    margin-top: 0px;

    input {
      padding: 14px 14px 14px 31.83px;
    }
  }
}
