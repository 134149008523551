@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.Container-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 408px;
  padding: 0px 0px;
  margin: 24px auto;
}
.Place-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px 40px 32px 40px;
  background: #ffffff;
  border-radius: 8px;
}

.Place-holder-Image {
  width: 80px;
  height: 80px;
  top: 5px;
  left: 5px;
}
.Place-holder-Message-Pig {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 0px 16px;
  color: #7c878e;
}
.Place-holder-Message2-Pig {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 16px;
  color: #7c878e;
}
.Place-holder-Message-Glass {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #7c878e;
  margin: 16px 0px;
}
.Place-holder-Message2-Glass {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 0px 38px 16px;
  color: #7c878e;
}
.Place-holder-Link-Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 200px;
  height: 40px;
  background: #0bbef0;
  color: #ffffff !important;
  border: none;
  font-family: "Nunito";
  mix-blend-mode: normal;
  border-radius: 48px;
  flex: none;
  order: 2;
  flex-grow: 0;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

@media (min-width: $desktop) {
  .Container-holder {
    width: 90%;
    max-width: 1110px;
  }
  .Place-holder-Message-Pig {
    font-size: 32px;
    line-height: 40px;
    margin: 21px 0px 8px;
  }
  .Place-holder-Message2-Pig {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .Place-holder-Message-Glass {
    font-size: 32px;
    line-height: 40px;
    margin: 16px 0px 8px;
  }
  .Place-holder-Message2-Glass {
    font-size: 32px;
    line-height: 40px;
    margin: 0px 278px 16px;
  }
}
