@import "../../../styles/breakpoints.scss";
.text-image-container{
  padding: 16px 28px 32px 28px;
  
  .text-container {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    
  }

  .image-container {
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: auto;
    row-gap: 16px;
    align-items: center;
    margin: 0px;

    @media (max-width: $extra-wide) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: $wide) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: $desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $medium-smartphone) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: $smartphone) {
      padding: 0px;
      width: 100%;
      column-gap:5px;
    }
    .image-content-container{
      width: 100%;
      display: flex;
      justify-content: center;
      .image {
        width: 150px;
        height: 150px;
        border-radius: 8px;   
        margin: 0px;     
        @media screen and (max-width: $smartphone) {
          width: 136px;
          height: 136px;
        }
      }
    }
  }

}