@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  flex-direction: column;
  &__copy {
    color: $middle-gray;
    overflow: hidden;
  }
}
