@import "../../../../styles/breakpoints.scss";
.image {
  width: 150px !important;
  border-radius: 8px;
  margin-bottom:-8px;
  margin-left: 10px;
  @media screen and (max-width: $smartphone) {
    width: 50%;
    height: auto;
    margin: auto;
    display: block;
    margin-bottom: 4px;
  }

}