@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px 32px;
    width: 100%;
    height: 1027px;
    background: #F4F5F5;
    border-radius: 0px 100px 0px 0px;
  }

.profile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 27px 0px 28px;
    position: static;
    width: 100%;
    height: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.profile-go-back {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 120px;
  height: 40px;
  border-radius: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  cursor: pointer;
}

.profile-content-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 76px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 22px;
  
    p {
      position: static;
      width: 48px;
      height: 24px;
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #475061;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 8px;
    }
  
    &__icon {
      margin: 0px 8px;
    }
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 100%;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 32px 0px;

    .space {
      margin: 0 0 24px 0;
    }

    .title-info-profile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: static;
        width: 100%;
        flex: none;
        order: 0;
        flex-grow: 0;
    } 
    
    .description-subtitle-profile {
        font-size: 18px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 0px 0px 12px;
        position: static;
        width: 100%;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 12px 0px 20px;
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        color: #475061;        
    }
}  

.title-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    position: static;
    width: 100%;
    height: 32px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #475061;
    border-left: 4px solid #0bbef0;
    padding-left: 10px;
}

@media (min-width: $desktop) {
  
    .profile-container {
      align-items: center;
      padding: 24px 0px 56px;
    }
  
    .profile-content {
      padding: 0px;
      margin: 0 auto;
      width: 91%;
      max-width: 1110px;
    }

}