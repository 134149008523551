@import "../../../styles/breakpoints.scss";

.simple-text-bullet-container {
  // display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 14px 32px;
  width: 100%;
  max-width: 1110px;

  background: #f2f2f2;

  @media screen and (max-width: $smartphone) {
    width: 320px;
    padding: 16px 16px 12px 16px;
  }

  .simple-text-bullet-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0px;
    row-gap: 16px;

    @media screen and (max-width: $smartphone) {
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: $smartphone) {
      width: 288px;
    }

    .simple-text-bullet-list {
      list-style: none;
      counter-reset: counter;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      width: 100%;
    }
    .simple-text-bullet-content-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 0px 14px;
      &:only-child {
        width: 100%;
        // @media screen and (max-width: $smartphone) {
        //   width: 288px;
        // }
      }

      @media screen and (max-width: $smartphone) {
        padding: 0px;
      }
      .simple-text-bullet-title-container {
        padding-bottom: 8px;
        .simple-text-bullet-title {
          font-family: Nunito;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          color: #5d5a6a;
        }
      }
      .simple-text-bullet-content {
        // width: 438px;
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 162%;
        color: #5d5a6a;

        @media screen and (max-width: $smartphone) {
          width: 288px;
        }
      }
    }
  }
}
