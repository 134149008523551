@import "./colors.scss";
@import "./fonts.scss";
@import "./breakpoints.scss";

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: $gray;
}
#root {
	overflow: hidden;
}
p {
	margin: 0;
	font-family: 'Nunito';
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

h1,
h2,
h3,
h4 {
	margin: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.margin-title-description {
	height: 34px;
}

@media screen and (max-width: 500px) {
	.margin-title-description {
		height: 20px;
	}
}

.util {
	&.__w {
		width: 100%;
	}
	@media screen and (max-width: 380px) {
		&.__tty {
			transform: translateY(12px);
		}
		&.__ttyt {
			transform: translateY(18px);
		}
	}
}

.util{
  &.__w{
    width: 100%;
  }
}
.text-align-end {
	text-align: end;
}

body.no-overflow {
	overflow: hidden;
}

.no-decoration {
	text-decoration: none;
	color: black;
}

.hidden {
	display: none;
}
