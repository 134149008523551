@import "../../../styles/colors";
@import "../../../styles/breakpoints.scss";

figure {
  margin: 0 !important;
  padding: 0 !important;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.content_text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.countdown_text {
  display: block;
}

.investbutton_text{
  height: 112px;
}

.texto_float {
  width: 100%;
  position: fixed;
  height: 92px;
  background: #282828;
  z-index: 50;

  &__img {
    display: none;
  }

  &__info {
    display: none;
  }

  &__description {
    float: left;
    align-items: center;
    color: #ffffff;
    font-family: Nunito;
    font-style: normal;
    margin: 12px 0 12px 0;
    width: 53.3%;
    height: auto;

    &__campaign_index {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }

    &__campaign {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0px 0px;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  .withoutDes {
    width: 100%;
    padding: 0 20px 0 20px;
    margin: 14px 0 14px 0;
    .texto_float__description__campaign_index {
      font-size: 12px;
      -webkit-line-clamp: 2;
    }
    .texto_float__description__campaign {
      font-size: 16px;
      line-height: 24px;
      -webkit-line-clamp: 2;
    }
  }

  .countdown_description {
    width: 100%;
    padding: 0 20px 0 20px;
    margin: 8px 0 8px 0;
    .texto_float__description__campaign_index {
      font-size: 12px;
      -webkit-line-clamp: 2;
    }
    .texto_float__description__campaign {
      font-size: 16px;
      line-height: 24px;
      -webkit-line-clamp: 2;
    }
  }

  &__button {
    float: left;
    margin: 25.5px 0 25.5px 4px;
    width: 131px;
    height: 41px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    button {
      justify-content: center;
      align-items: center;
      position: static;
      width: 100%;
      height: 100%;
      font-family: Nunito;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      mix-blend-mode: normal;
      border-radius: 100px;
      cursor: pointer;
    }

    .action {
      background: #7d7bfa;
      border: #7d7bfa;
    }

    .note {
      background: #496cff;
      border: #496cff;
    }

    .debt {
      background: #02bac6;
      border: #02bac6;
    }
  }
  .without {
    width: 0;
    margin: 25.5px 0 25.5px 0;
    button {
      width: 0;
    }
  }
  .countdown {
    width: 100%;
    display: flex;
    height: auto;
    padding: 0;
    margin: 4px 0 8px 0;
  }
}
.countdown_float {
  height: auto;
}

.investbutton_float{
  height: 112px;
}

@media (min-width: $desktop) {
  .content_text {
    width: 1110px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
  }
  .investbutton_text{
    height: auto;
  }

  .texto_float {
    top: 72px;
    height: 80px;

    &__img {
      display: block;
      position: static;
      width: 52.11px;
      height: 52.11px;
      float: left;
      background: #ffffff;
      border-radius: 70px;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    &__info {
      display: block;
      float: left;
      align-items: center;
      color: #ffffff;
      font-family: Nunito;
      font-style: normal;
      margin: 8px 0 8px 24px;
      width: 240px;

      &__company_index {
        height: 16px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
      }

      &__company {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        height: auto;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    &__description {
      float: left;
      align-items: center;
      color: #ffffff;
      font-family: Nunito;
      font-style: normal;
      margin: 8px 0 8px 48px;
      max-width: 445px;
      width: 445px;

      &__campaign_index {
        height: 16px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
      }

      &__campaign {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        height: auto;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .withoutDes {
      width: 100%;
      padding: 0 20px 0 20px;
      margin: 8px 0 8px 48px;
    }

    .countdown_description {
      width: 100%;
      padding: 0;
      margin: 8px 0 8px 48px;
    }

    &__button {
      float: right;
      padding: 0px;
      margin: 16px 0 16px 48px;
      width: 255px;
      height: 48px;
      align-items: center;
      justify-content: center;

      button {
        justify-content: center;
        align-items: center;
        position: static;
        width: 100%;
        height: 100%;
        font-family: Nunito;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        mix-blend-mode: normal;
        border-radius: 100px;
      }

      .action {
        background: #7d7bfa;
        border: #7d7bfa;
      }

      .note {
        background: #496cff;
        border: #496cff;
      }

      .debt {
        background: #02bac6;
        border: #02bac6;
      }
    }
    .without {
      width: 0;
      margin: 16px 0 16px 0;
      button {
        width: 0;
      }
    }
    .countdown {
      width: 255px;
      display: flex;
      height: auto;
      padding: 0;
      margin: 6px 0 6px 48px;
    }
  }
}
