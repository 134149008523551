@import "../../../styles/breakpoints.scss";

.text__image__container {
  padding: 16px 28px 32px 28px;
  .image__container {
    margin-top: 8px;
    display: grid;
    column-gap: 10px; 
    row-gap: 16px;
    align-items: start;
    padding: 0px;   

    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: none;


    .image__content__container {
      width: 100%;
      display: block;
      padding: 0px;
      margin: 0px;
      margin-bottom: -40px;
      align-items: center;
      .image {
        border-radius: 8px;
        width: 50%;
        height: auto;
        margin: auto;
        display: block;
      }
      .description {
        text-align: center;
        font-family: Nunito;
        font-size: 16px;
        line-height: 24.55px;
        font-style: normal;
        font-weight: bold;
        display:flex;
        justify-content: space-around;
        height:auto;
        padding: 0;
        align-items: start;
        margin-bottom: 40px;
        margin-top: 12px;
      }
      .shares {
        color: #7d7bfa;
      }
      .noteConvertible {
        color: #496cff;
      }
      .debt {
        color: #02bac6;
      }
    }
    .investor_name{
      text-align: center;
      font-family: Nunito;
      font-size: 18px;
      line-height: 24px;
      font-style: normal;
      font-weight: 800;
      display:flex;
      height:auto;
      justify-content: space-around;
      word-wrap: break-word;
      padding: 0px;
      color: #31302f;
      align-items: center;
      margin-bottom: -15px;
    }
    .intern__container {
      margin: 0px;
      padding: 0px;
    }
  }
  
}


@media (min-width: $responsive-desktop) {
  .text__image__container {
    .image__container {
      grid-template-columns: repeat(3, 1fr);
      .image__content__container {
        .image__container {
          column-gap: 10px; 
        }
        .image {
          width: 100%;
          height: 150px;
          border-radius: 8px;
        }
        .investor_name{
          margin-bottom: -10px;
        }
        .description {
          line-height: 26.06px;
        }
      }
      .investor_name{
        min-height:3em;
      }
    }
  }
  
}
 
@media (min-width: $desktop) {
  .text__image__container {
    .image__container {
      grid-template-columns: repeat(4, 1fr);
      .image__content__container {
        .image__container {
          column-gap: 10px; 
        }
        .image {
          width: 100%;
          height: 150px;
          border-radius: 8px;
        }
        .investor_name{
          margin-bottom: -10px;
        }
        .description {
          line-height: 26.06px;
        }
      }
      .investor_name{
        min-height:3em;
      }
    }
  }
  
}
 
@media (min-width: $wide) {
  .text__image__container { 
    .image__container {
      grid-template-columns: repeat(5, 1fr);
      .image__content__container {
        .image__container {
          column-gap: 10px; 
        }
        .image {
          width: 100%;
          height: 150px;
          border-radius: 8px;
        }
        .investor_name{
          margin-bottom: -10px;
        }
        .description {
          line-height: 26.06px;
        }
      }
      .investor_name{
        min-height:3em;
      }
    }
  }
  
}
 
@media (min-width: $extra-wide) {
  .text__image__container {
    .image__container {
      grid-template-columns: repeat(6, 1fr);
      .image__content__container {
        .image__container {
          column-gap: 10px; 
        }
        .image {
          width: 100%;
          height: 150px;
          border-radius: 8px;
        }
        .investor_name{
          margin-bottom: -10px;
        }
        .description {
          line-height: 26.06px;
        }
      }
      .investor_name{
        min-height:3em;
      }
    }
  }
  
}
 


