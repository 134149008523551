@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.otp {
  margin-top: 120px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  gap: 32px;
  margin-bottom: 50px;

  &__InfoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    max-width: 548px;

    @media screen and (max-width: $smartphone) {
      gap: 16px;
    }
  }

  &__Title {
    color: $mako;
    font-family: Nunito;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    @media screen and (max-width: $smartphone) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__Subtitle {
    color: #5B6770;
    font-family: Nunito;
    font-size: 24px;
    text-align: center;
    max-width: 548px;
    font-weight: 700;
    @media screen and (max-width: $smartphone) {
      font-size: 18px;
      line-height: 24px;
      max-width: 279px;
      text-align: initial;
    }
  }

  &__InfoSMS {
    color: $mako;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 420px;
    @media screen and (max-width: $smartphone) {
      font-size: 14px;
      line-height: 20px;
      max-width: 279px;
      text-align: initial;
    }
  }

  &__InputInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 400px;
    height: 72px;
    @media screen and (max-width: $smartphone) {
      max-width: 272px;
    }
  }

  .shorText {
    @media screen and (max-width: $smartphone) {
      height: 56px;
    }
  }

  &__InfoTimer {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $mako;
    b {
      color: #FF7D00;
    }
  }

  .infoCode-2,.infoCode-3 {
    color: #FF7D00 !important;
  }

  .infoCode-4,.timeUp {
    color: #FF317B !important;
    b {
      color: #FF317B !important;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 40px;
    @media screen and (max-width: $smartphone) {
      gap: 24px;
    }
  }

  &__InfoText {
    color: #5B6770;
    font-family: Nunito;
    font-size: 17px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 552px;
    @media screen and (max-width: $smartphone) {
      font-size: 14px;
      max-width: 375px;
    }
  }

  &__NewCodeLink {
    color: $bright-turquoise;
    font-family: Nunito;
    font-size: 14px;
    text-decoration-line: underline;
    cursor: pointer;
    @media screen and (max-width: $smartphone) {
      line-height: 20px;
    }

    .disabled {
      cursor: none;
      pointer-events:none;
      opacity: 0.24;
    }
  }

  &__SendButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 10px;
    width: 200px;
    height: 40px;
    background: #0BBEF0;
    mix-blend-mode: normal;
    border-radius: 48px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    border: #0BBEF0;
    cursor: pointer;

    &:disabled {
      opacity: 0.24;
      cursor: default;
    }
  }

  @media (max-width: $wide) {
    max-width: 960px;
  }

  @media (max-width: $tablet) {
    max-width: 720px;
  }

  @media (max-width: $smartphone) {
    max-width: 540px;
    margin-top: 33px;
  }
  
}

.otpOops {
  margin-top: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  gap: 60px;

  @media screen and (max-width: $smartphone) {
    margin-top: 0px;
    padding: 32px 0px;
    gap: 48px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 80px;

    @media screen and (max-width: $smartphone) {
      padding: 0px 28px;
      gap: 48px;
      max-width: 375px;
    }
  }

  &__title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 120px;
    line-height: 68px;
    text-align: center;
    color: #5B6770;
    @media screen and (max-width: $smartphone) {
      font-size: 76px;
    }
  }

  &__copy-info {
    max-width: 676px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 24px;
    color: #5B6770;
    @media screen and (max-width: $smartphone) {
      max-width: 319px;
      b {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }
      font-size: 18px;
      line-height: 24px;
    }
  }
  
  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 10px;
    width: 200px;
    height: 40px;
    background: #0BBEF0;
    border: #0BBEF0;
    mix-blend-mode: normal;
    border-radius: 48px;
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
}

.failedAttempts {
  margin-top: 236px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  gap: 60px;
  margin-bottom: 50px;

  @media screen and (max-width: $smartphone) {
    padding: 32px;
    margin-top: 0px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 40px;
  }

  &__icon {
    margin: 21px;
  }

  &__title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    max-width: 548px;
    text-align: center;
    color: #5B6770;
    @media screen and (max-width: $smartphone) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 10px;
    width: 200px;
    height: 40px;
    background: #0BBEF0;
    border: #0BBEF0;
    mix-blend-mode: normal;
    border-radius: 48px;
    color: #FFFFFF;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }

}
