@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.FilterBy-container {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;

  .Filter-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    width: 100%;
    height: 48px;
    background: #ffffff;
    border-radius: 5px;

    img {
      width: 24px;
      height: 24px;
      transition: all 0.35s;
      transform: rotate(0deg);
    }

    p {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #7e7d7b;
    }
  }
}

.rotate {
  transform: rotate(180deg) !important;
}

.margin {
  margin-bottom: 18px;
}

.borde {
  border-bottom: 1px solid #e4e4e4;
}

.border-right {
  border-right: none !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.OptionFilter-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .OptionFilter__shadow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    max-width: 320px;
    height: 364px;
    filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.12));
    z-index: 3;
    transition: all 0.3s ease-out;

    .triangle {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0px 16px;
      width: 100%;
      height: 22px;
    }

    .OptionFilter__scroll {
      position: absolute;
      width: 100%;
      max-width: 320px;
      height: 350px;
      top: 19px;
      overflow: auto;
      border-radius: 8px;

      .OptionFilter {
        padding: 0px;
        width: 100%;
        max-width: 320px;

        .filtersBox-title__container {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          background: #ffffff;
          padding: 25px 27px 9px;

          .filtersBox-title {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #3f3f3f;
          }
        }

        .filtersBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          max-width: 320px;
          width: 100%;
          left: 0px;
          top: 22px;
          background: #ffffff;

          .acordionFilter {
            width: 100%;

            .acordion-checkbox {
              position: absolute;
              opacity: 0;
              z-index: -1;
              &:checked + label > img {
                transform: rotate(0deg);
              }

              &:checked ~ div {
                visibility: visible;
                max-height: 100vh;
              }
            }

            .acordion-label {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              height: 56px;
              margin: 0px 29px;

              p {
                font-family: "Nunito";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #3f3f3f;
              }

              img {
                transform: rotate(-180deg);
                transition: all 0.35s;
              }
            }

            .acordion-content {
              visibility: hidden;
              max-height: 0vh;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px 32px 0px;
              background: #f4f4f4;
              box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.077524);

              .acordion-option {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 18px;
                cursor: pointer;
                height: 26px;
                gap: 16px;

                .check-option {
                  width: 24px;
                  height: 24px;
                }

                .background__check {
                  background-image: url("../../assets/icons/CheckOptionFilter.png");
                  background-repeat: no-repeat;
                  background-position: center;
                  border-radius: 30px;
                }

                .background__no-check {
                  background-image: url("../../assets/icons/NoCheckOptionFilter.png");
                  background-repeat: no-repeat;
                  background-position: center;
                  border-radius: 30px;
                }
                p {
                  font-family: "Nunito";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;
                  display: flex;
                  align-items: center;
                  color: #4e567a;
                }
              }
            }
          }
        }
      }
      .filtersBox-container__button {
        width: 100%;
        height: 72px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 16px 0px;
        border-top: 1px solid #e4e4e4;
        background: #ffffff;

        .restore {
          width: 135px;
          height: 40px;
          border: 2px solid #0bbef0;
          box-sizing: border-box;
          border-radius: 23px;
          background: #ffffff;
          cursor: pointer;

          p {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #0bbbef;
          }
        }

        .filter {
          width: 130px;
          height: 40px;
          background: #0bbbef;
          border: 2px solid #0bbef0;
          box-sizing: border-box;
          border-radius: 23px;
          cursor: pointer;

          p {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }

    .OptionFilter__scroll::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .OptionFilter__scroll::-webkit-scrollbar:vertical {
      width: 10px;
    }

    .OptionFilter__scroll::-webkit-scrollbar-button:increment {
      display: none;
    }

    .OptionFilter__scroll::-webkit-scrollbar-button {
      display: none;
    }

    .OptionFilter__scroll::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    .OptionFilter__scroll::-webkit-scrollbar-thumb {
      background-color: #797979;
      border-radius: 20px;
      border: 2px solid #f1f2f3;
    }

    .OptionFilter__scroll::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    .OptionFilter__scroll::-webkit-scrollbar-track-piece{
      background-color: #ffffff;
      border-radius: 0px 8px 8px 0px;
    }
  }
}

@media (min-width: $desktop) {
  .rotate {
    transform: rotate(0deg) !important;
  }

  .FilterBy-container {
    width: 20.3%;
    padding-right: 30px;

    .Filter-label {
      padding: 12px;

      img {
        transform: rotate(180deg);
      }
    }
  }

  .OptionFilter-container {
    .OptionFilter__shadow {
      flex-direction: column-reverse;
      bottom: 50px;
      left: calc(100% - 58px);
      width: 792px;
      max-width: none;
      height: 401px;
      filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.12));

      .triangle {
        align-items: flex-start;
        height: 20px;
        gap: 10px;

        img {
          transform: rotate(180deg);
        }
      }

      .OptionFilter__scroll {
        width: 100%;
        max-width: none;
        height: 381px;
        top: 0;
        overflow: hidden;
        border-radius: 8px;

        .OptionFilter {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          width: 100%;
          max-width: none;
          height: 293px;
          border-radius: 4px;
          max-width: none;

          .filtersBox-title__container {
            padding: 16px 32px;
            gap: 8px;
            width: 792px;
            height: 56px;
            border-radius: 4px 4px 0px 0px;
            border-bottom: 1px solid #d4d7d9;

            .filtersBox-title {
              line-height: 24px;
            }
          }

          .filtersBox {
            width: 100%;
            max-width: none;
            height: 236px;
            max-width: none;
            flex-direction: row;
            border-bottom: 1px solid #d4d7d9;
            border-radius: 0px 0px 4px 4px;
            padding: 0;

            .acordionFilter {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              width: 264px;
              height: 236px;
              background: #ffffff;
              border-right: 1px solid #d4d7d9;

              .acordion-label {
                align-items: flex-start;
                padding: 16px 32px;
                width: 100%;
                height: 56px;
                background: #ffffff;
                margin: 0px;
                border: none;

                img {
                  display: none;
                }
              }

              .acordion-content {
                padding: 16px 32px;
                width: 100%;
                height: 180px;
                background: #f4f5f5;
                border-top: 1px solid #d4d7d9;
                box-shadow: none;
                gap: 12px;

                .acordion-option {
                  align-items: center;
                  padding: 2px;
                  width: 200px;
                  height: 28px;
                  margin: 0px;

                  p {
                    font-family: "Nunito";
                    line-height: 20px;
                    color: #475061;
                  }
                }
              }
            }
          }
        }
        .filtersBox-container__button {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding: 24px 32px 24px 0px;
          gap: 24px;
          width: 100%;
          height: 88px;
          border: none;

          .restore {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 10px;
            width: 200px;
            height: 40px;
            border-radius: 48px;

            p {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
            }
          }

          .filter {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 10px;
            width: 200px;
            height: 40px;
            border-radius: 48px;

            p {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
