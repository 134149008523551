@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.investment {
  width: 100%;
  margin-bottom: 24px;

  &__tooltip {
    background: #62618b !important;
    border-radius: 8px !important;
    font-family: "Nunito" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 12px !important;
    width: 216px !important;
    height: auto !important;
    @media screen and (max-width: $smartphone) {
      left: 44px !important;
    }
  }

  &__tooltip::after {
    border-top-color: #62618b !important;
  }
  @media screen and (max-width: $smartphone) {
    .tooltip_accordeon_deb::after {
      left: 63px !important;
    }

    .tooltip_accordeon_share::after {
      left: 148px !important;
    }

    .tooltip_accordeon_note::after {
      left: 191px !important;
    }
  }

  &__date {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475061;
  }

  &__accordion-item {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    left: 0px;
    top: 28px;
    background: #ffffff;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
    overflow: hidden;
  }

  &__accordion-title {
    color: #55567f;
    font-family: Nunito;
    letter-spacing: 0;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
  }

  &__accordion-row_tag {
    display: flex;
    align-items: baseline;
    min-width: 35%;
    flex-direction: column;
    margin-bottom: 16px;
  }

  &__accordion-row_campaign {
    padding: 0 16px 16px 16px;
    display: flex;
    align-items: baseline;
    flex-grow: 1;
    width: 100%;
    height: 60px;
  }

  &__content {
    max-height: 0;
    transition: all 0.35s;
    color: #475061;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
    padding: 0 !important;
  }

  .Shares {
    background: #7d7bfa;
    width: 98px;
    height: 32px;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }

  .Shares_text {
    color: #7d7bfa;
  }

  .ConvertibleNote {
    width: 143px;
    height: 32px;
    background: #496cff;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }

  .ConvertibleNote_text {
    color: #496cff;
  }

  .Debt {
    background: #02bac6;
    width: 85px;
    height: 32px;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }

  .Debt_text {
    color: #02bac6;
  }

  .Equity {
    background: #e6e6e6;
    width: 60px;
    height: 32px;
    margin: 0px 12px 0px 0px;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #475061;
  }

  .Shares_backGround {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.33' height='13.33' viewBox='0 0 16 17' fill='none'%3E%3Crect width='16' height='16' rx='8' transform='matrix(-1 0 0 1 16 0.5)' fill='white'/%3E%3Cellipse cx='7.99999' cy='5.30001' rx='1.2' ry='1.2' fill='%237D7BFA'/%3E%3Crect x='6.79999' y='7.3' width='2.4' height='5.6' rx='1.2' fill='%237D7BFA'/%3E%3C/svg%3E");
  }

  .ConvertibleNote_backGround {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.33' height='13.33' viewBox='0 0 16 17' fill='none'%3E%3Crect width='16' height='16' rx='8' transform='matrix(-1 0 0 1 16 0.5)' fill='white'/%3E%3Cellipse cx='8' cy='5.3001' rx='1.2' ry='1.2' fill='%237D7BFA'/%3E%3Crect x='6.8' y='7.30005' width='2.4' height='5.6' rx='1.2' fill='%237D7BFA'/%3E%3C/svg%3E");
  }

  .Debt_backGround {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.33' height='13.33' viewBox='0 0 16 17' fill='none'%3E%3Crect width='16' height='16' rx='8' transform='matrix(-1 0 0 1 16 0.5)' fill='white'/%3E%3Cellipse cx='8' cy='5.30001' rx='1.2' ry='1.2' fill='%2302BAC6'/%3E%3Crect x='6.8' y='7.3' width='2.4' height='5.6' rx='1.2' fill='%2302BAC6'/%3E%3C/svg%3E");
  }

  &__business-line {
    display: flex;
    margin-bottom: 16px;
  }

  &__tags {
    &__icon {
      background-repeat: no-repeat;
      background-position: 50%;
      width: 16px;
      height: 16px;
      margin: 0 0 0 8px;
      padding: 6.665px;
      cursor: pointer;
    }
  }

  .canceled {
    color: #ff317b;
  }

  &__status {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    justify-content: flex-start;
    color: #475061;

    &__oval {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin: 0px 12px 0px 4px;
      align-self: center;
      border: 2px solid #A2AAAD;
    }

    .active {
      background: #39fbbf;
    }

    .canceled {
      background: #ff317b;
    }

    .exchange {
      background: #FDDA25;
    }
  }

  .tooltip_text_destiny {
    color: #ffffff !important;
    opacity: 0.84 !important;
    width: auto !important;
    background: #383c5d !important;
    border-radius: 8px !important;
  }

  .tooltip_text_destiny::after {
    display: none !important;
  }

  &__destiny {
    width: 100%;
    display: flex;
    font-family: Nunito;
    font-size: 16px;
    height: 38px;
    align-items: center;

    &__name {
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 44px;
      margin-left: 0px;
      white-space: initial;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .investTo {
      color: #475061;
    }
    .campaign_name {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &__accordion-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
    &:checked + label > .icon > label > svg {
      transform: rotate(180deg);
    }

    &:checked ~ div {
      max-height: max-content;
      border-top: none !important;
    }
  }

  &__accordion-label {
    display: flex;
    padding: 16px 16px 0 16px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin: 8px;
      svg {
        cursor: pointer;
        transform: rotate(0deg);
        width: 14px;
        height: 14px;
        transition: all 0.35s;
        color: #55567f;
      }
    }
  }

  &__accordion-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}

@media (min-width: $tablet) {
  .investment {
    @media (min-width: 769px) {
      width: 90%;
    }
    margin: 0 auto 24px;
    max-width: 1110px;
    &__accordion-row_campaign {
      position: absolute;
      width: auto;
      margin: 16px 0 16px 345px;
      max-width: 589px;
      min-width: 320px;
      width: 42%;
      padding: 0;
    }

    &__accordion-row_tag {
      flex-direction: row;
      margin-bottom: 0;
    }

    &__destiny {
      height: 38px;
      &__name {
        -webkit-line-clamp: 1;
      }
    }
    &__status {
      &__oval {
        margin: 0px 12px 0px 24px;
      }
    }
  }
}

@media (min-width: $desktop) {
  .investment {
    padding: 0;
    margin: 0px auto;
    width: 90%;
    max-width: 1110px;

    &__destiny {
      height: 22px;
    }

    &__accordion-label {
      height: 56px;
      padding: 12px 37px 12px 24px;
      align-items: normal;
      .icon {
        margin: 4px 0 0;
      }
    }

    &__accordion-row_campaign {
      margin: 16px 0 16px 445px;
      min-width: 400px;
      max-width: 589px;
      width: 40%;
      padding: 0;
    }
  }
}
