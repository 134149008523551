@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.searchInvestDetail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: $desktop) {
  
}
