@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.large-button {
  padding: 10px 60px;
  font-size: 18px;
  font-weight: bold;
  @media screen and (max-width: $smartphone) {
    padding: 8px 20px;
  }
}

.validation {
  &__error {
    color: $red;
    font-size: 16px;
    font-family: "nunito";
    display: flex;
    align-items: center;
    &_img {
      margin-left: 5px;
    }
  }
}
