@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.investDetail {
  background: #f9f9f9;

  .inactive_buttons_invest_detail {
    opacity: 0.5;
    cursor: default !important;
  }

  .tooltip_invest_detail {
    background: #62618b !important;
    border-radius: 8px !important;
    font-family: "Nunito" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 12px !important;
    width: 216px !important;
    height: auto !important;
    @media (max-width: $tablet) {
      left: auto !important;
      right: 28px !important;
    }
  }

  .tooltip_invest_detail:after {
    border-top-color: #62618b !important;
    @media (max-width: $tablet) {
      left: auto !important;
      right: 22px !important;
    }
  }

  .card_invest_detail {
    display: flex;
    flex-direction: column;
  }
  .line_invest_detail {
    height: 0;
    border: 0.5px solid #d4d7d9;
    width: 100%;
  }
  .content_invest_detail {
    flex-wrap: inherit;
    display: flex;
  }
  .half_content_invest_detail {
    width: 50%;
    margin: 0px auto;
  }
  .half_content_invest_detail_80 {
    width: 80%;
    margin: 0px auto;
  }
  .half_content_invest_detail_30 {
    width: 30%;
    margin: 0px auto;
  }
  .half_content_invest_detail_55 {
    width: 55%;
    margin: 0px auto;
  }
  .half_content_invest_detail_45 {
    width: 45%;
    margin: 0px auto;
  }
  .open_invest_state {
    background: #d0ddf1;
    color: #2c5697;
  }
  .awarded_invest_state {
    background: #2c5697;
    color: #ffffff;
  }
  .close_invest_state {
    background: #ffc2d8;
    color: #ff317b;
  }
  .debt_invest {
    background: #02bac6;
  }
  .share_invest {
    background: #7d7bfa;
  }
  .note_invest {
    background: #496cff;
  }
  .container_button {
    padding: 24px 0 0px;
  }
  &__icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.67' height='16.67' viewBox='0 0 18 18' fill='none'%3E%3Crect width='16.6667' height='16.6667' rx='8.33333' transform='matrix(-1 0 0 1 17.4298 0.666668)' fill='%23475061'/%3E%3Cellipse cx='9.09642' cy='5.2963' rx='1.38889' ry='1.38889' fill='white'/%3E%3Crect x='7.70753' y='7.61112' width='2.77778' height='6.48148' rx='1.38889' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    width: 16.67px;
    height: 16.67px;
    margin: 13.67px 21.57px 0 0;
    float: right;
    cursor: pointer;
  }
  &__sub_title_note {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #475061;
    padding: 8px 0 0 20px;
  }
  &__sub_title_share {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475061;
    padding: 8px 0 0 20px;
  }
  &__sub_value_share {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #475061;
    padding: 4px 0 8px 20px;
  }
  &__sub_title {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #475061;
    padding: 12px 0 0 20px;
    max-width: 115px;
  }
  &__sub_value {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #475061;
    padding: 4px 0 12px 20px;
  }
  &__sub_value_note {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #475061;
    padding: 4px 0 12px 20px;
  }
  &__title {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475061;
    padding: 12px 20px 4px;
    text-align: left;
  }
  &__value {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #475061;
    padding: 0 20px 12px;
    text-align: left;
  }
  &__title_amount {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475061;
    padding: 16px 0 0 20px;
    text-align: left;
  }
  &__tab_state {
    mix-blend-mode: normal;
    border-radius: 15.5px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin: 16px 20px 0 0;
    float: right;
    height: 22px;
    width: 90px;
    padding: 3px 0 4px;
  }
  &__cop_raised {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #475061;
    padding: 8px 0 0 20px;
    float: left;
  }
  &__amount_raised {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #475061;
    padding: 8px 0 0 4px;
    float: left;
  }
  &__bar_invest {
    background: #dfdfdf;
    border-radius: 6.5px;
    margin: 24px 20px 0;
    height: 12px;
    &__progress {
      border-radius: 8px;
      height: 12px;
      float: left;
    }
  }
  &__porcent_invest {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #004997;
    padding: 12px 0 0 20px;
    float: left;
  }
  &__porcent_invest_text {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #004997;
    padding: 12px 0 0 4px;
    float: left;
  }
  &__goal_text {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #475061;
    float: right;
    padding: 12px 4px 16px 0;
  }
  &__goal_value {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #475061;
    padding: 12px 20px 16px 0;
    float: right;
  }
  &__button_invest {
    mix-blend-mode: normal;
    border-radius: 48px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #ffffff;
    height: 40px;
    padding: 8px 10px;
    cursor: pointer;
    width: 200px;
    margin: 0 auto;
  }
  &__cancel_invest {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #475061;
    padding: 12px 0 16px;
    cursor: pointer;
    width: 151px;
    margin: 0 auto;
  }
}

@media (min-width: $desktop) {
  .investDetail {
    .content_invest_detail_desktop {
      flex-wrap: inherit;
      display: flex;
    }
    .half_content_invest_detail_desktop {
      width: 50%;
      margin: 0px auto;
    }
    .card_deb_desktop {
      width: calc(100% / 3) !important;
    }
    .card_share_desktop {
      width: calc(100% / 2) !important;
    }
    .content_flex_desktop {
      flex-wrap: inherit;
      display: flex;
    }
    .display_invest_detail_desktop {
      display: none !important;
    }
    .border_vertical_desktop {
      border: 0.5px solid #d4d7d9;
    }
    .container_button {
      padding: 16px 0 0;
    }
    &__icon {
      margin: 9.67px 21.57px 0 0;
    }
    &__sub_title {
      padding: 8px 0 0 20px;
      max-width: 116px;
      min-width: 119px;
    }
    &__sub_value {
      margin: auto 0 0 0;
      padding: 17.5px 0 8px 20px;
    }
    &__button_invest {
      width: 200px;
      margin: 0 auto;
    }
    &__sub_title_share {
      padding: 12.75px 0 0 20px;
    }
    &__sub_value_share {
      padding: 4px 0 12.75px 20px;
    }
    &__sub_title_note {
      padding: 16.75px 0 0 20px;
      min-width: 146px;
    }
    &__sub_value_note {
      font-size: 20px;
      line-height: 24px;
      padding: 8px 0 16.75px 20px;
    }
    &__title {
      font-size: 18px;
    }
    &__value {
      font-size: 24px;
      line-height: 32px;
    }
    &__cop_raised {
      font-size: 24px;
      line-height: 32px;
    }
    &__amount_raised {
      font-size: 24px;
      line-height: 32px;
    }
    .margin_icon_container_desktop {
      margin: 0 !important;
    }
    .width_title_note {
      width: 146px !important;
      margin: 0;
    }
    .icon_note_desktop {
      margin: 18.42px 0 0 17.76px;
      float: left;
    }
  }
}
