@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.checkbox {
  &__container {
    background-color: $white;
    height: 26px;
    min-height: 26px;
    width: 26px;
    min-width: 26px;
    border-radius: 50%;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 1px solid $bright-turquoise;
    cursor: pointer;
  }

  &__center {
    height: 10px;
    width: 10px;
    object-fit: contain;
  }

  &__container_pyme {
    @extend .checkbox__container;
    border: 1px solid $royal-blue;
  }
}
