@import "../../styles/breakpoints.scss";


.text-image-container{
    padding: 28px 28px 32px 28px !important;
    
    .image-container{
      padding-top: 8px !important;
      grid-template-columns : repeat(6, 1fr) !important;
      column-gap: 28px !important;
      row-gap: 28px !important;

      .image-content-container{
        width: 150px !important;
        height: 150px !important;

        .image{
           width: 150px !important;
        }
      }
    }
  }


.accordion-sections {
  width: 100%;
  height: max-content;
  padding: 60px 28px 0px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    width: 100%;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #475061;
    left: 0;
    margin: 0 0 16px 0;
  }

  &__line {
    width: 100%;
    height: 0px;
    left: 28px;
    top: 5659px;
    border: 1px dashed #cdcdcd;
    margin: 32px auto 32px;
  }

  &__element {
    width: 100%;
    > div {
      > div {
        width: 100%;
        background: #f2f2f2;
        border-radius: 8px;
        height: max-content;
        > label {
          padding: 12px 16px;
          height: 48px;
          align-items: center;
          .icon {
            > svg {
              color: #475061;
              font-size: 18px;
            }
          }
          > p {
            font-family: Nunito;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 24.55px;
          }
        }
        *:checked ~ div {
          padding: 0;
          max-height: max-content;
          border-top: 1px dashed #cdcdcd;
        }
        > div {
          > div {
            margin-top: 0px;
            width: 100%;
          }
        }
      }
      *:checked + label > p {
        color: #7d7bfa;
      }
      *:checked + label > .icon > svg {
        color: #475061;
      }
    }
  }

  .action {
    > div {
      > div {
        > label {
          > p {
            color: #7d7bfa;
          }
        }
      }
    }
  }

  .noteConvertible {
    > div {
      > div {
        > label {
          > p {
            color: #496cff;
          }
        }
      }
    }
  }

  .Debt {
    > div {
      > div {
        > label {
          > p {
            color: #02bac6;
          }
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .accordion-sections {
    padding: 80px 128px 0px 128px;
  }
  .image-container {
    padding-top: 8px !important;
    grid-template-columns: repeat(2, 2fr) !important;
  }
}

@media (min-width: $responsive-desktop) {
  .accordion-sections {
    &__title {
      width: 100%;
      max-width: 1110px;
    }

    &__line {
      width: 100%;
      max-width: 1110px;
    }

    &__element {
      max-width: 1110px;
      > div {
        width: 100%;
        max-width: 1110px;
        > div {
          width: 100%;
          max-width: 1110px;
          > label {
            padding: 14px 28px 12px;
            height: 50px;
            align-items: center;
          }
        }
      }
    }
    .note {
      width: 100%;
      max-width: 1110px;
    }
  }
}

@media (max-width: $smartphone) {

  .text-image-container{
    padding: 16px 16px 16px 16px !important;
    
    .image-container{
      padding-top: 8px !important;
      grid-template-columns : repeat(2, 1fr) !important;
      column-gap: 16px !important;
      row-gap: 16px !important;

      .image-content-container{
        width: 136px !important;
        height: 136px !important;

        .image{
           width: 136px !important;
        }
      }
    }
  }
}




