@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.contact-data {
    &__section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;

        &__title {
            width: 100%;
            font-family: Nunito;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            color: #475061;
            left: 0;
            margin: 0 0 16px 0;
        }

        &__line {
            width: 100%;
            height: 0px;
            left: 28px;
            top: 5659px;
            border: 1px dashed #cdcdcd;
            margin: 32px auto 32px;
        }

        &__element {
            width: 100%;

            >div {
                >div {
                    width: 100%;
                    background: #f2f2f2;
                    border-radius: 8px;
                }
            }
        }
    }

    &__accordion {
        width: 100%;
        overflow: hidden;

        &__tooltip {
            background: #62618b !important;
            border-radius: 8px !important;
            font-family: "Nunito" !important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 12px !important;
            line-height: 16px !important;
            display: flex !important;
            align-items: center !important;
            text-align: center !important;
            color: #ffffff !important;
            padding: 12px !important;
            width: 216px !important;
            height: auto !important;

            @media screen and (max-width: $smartphone) {
                left: 44px !important;
            }
        }

        &__tooltip::after {
            border-top-color: #62618b !important;
        }

        @media screen and (max-width: $smartphone) {
            .tooltip_accordeon_deb::after {
                left: 63px !important;
            }

            .tooltip_accordeon_share::after {
                left: 148px !important;
            }

            .tooltip_accordeon_note::after {
                left: 191px !important;
            }
        }

        &__label__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        &__accordion-item {
            width: 100%;
            height: max-content;
            display: flex;
            flex-direction: column;
            padding: 0px;
        }

        &__content {
            width: 100%;
            max-height: 0;
            display: flex;
            flex-direction: column;
            position: static;
            left: 0px;
            top: 28px;
            background: #ffffff;
            border-radius: 8px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            overflow: hidden;
        }

        &__data-container {
            padding: 24px;
        }

        &__alert-container {
            padding: 24px 24px 0 24px;
        }        

        &__row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 24px;
            border-bottom: 1px solid #cdcdcd;
            border-radius: 8px;
            padding: 24px;
            gap: 10px;
            background: #F4F5F5;

            @media screen and (max-width: $tablet) {
                flex-direction: column;
            }

            &:first-child {
                border-bottom: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                padding-bottom: 0px;
            }

            &:nth-child(2) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            &:last-child {
                padding-bottom: 24px;
            }

            &__tooltip p {
                display: inline;
            }
        }

        &__column {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &-label {
            display: flex;
            font-family: Nunito;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0%;
            color: #0BBEF0;
            margin-bottom: 0px;

            &:first-child {
                margin-bottom: 12px;
            }

            &-title {
                height: 56px;
                font-family: Nunito;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0 !important;
                color: #475061;
                padding-top: 8px;
                padding-right: 24px;
                padding-bottom: 8px;
                padding-left: 24px;
                gap: 8px;
                border-bottom: 1px solid #D4D7D9;
                align-items: center;
            }


            .icon {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    cursor: pointer;
                    transform: rotate(0deg);
                    width: 14px;
                    height: 14px;
                    transition: all 0.35s;
                    color: #55567f;
                }
            }
        }

        &-code-select {
            width: 20% !important;
            background: #ffffff;
            height: 48px;
            border-radius: 8px;
            font-family: Nunito;
            font-weight: 700;
            font-size: 14px !important;
            line-height: 20px;
            letter-spacing: 0%;
            color: #475061;

            @media screen and (max-width: $tablet) {
                width: 50% !important;
            }


            &::after {
                top: 20px !important;
                width: 16px !important;
                height: 11px !important;
                right: 16px !important;

                @media screen and (max-width: $tablet) {
                    top: 21px !important;
                    right: 8px !important;
                }
            }

            &:focus {
                &:after {
                    transform: rotate(180deg) !important;
                }
            }

            select {
                padding: 12px 16px;
                &:disabled {
                    color: #A2AAAD;
                }
                option {
                    font-family: Nunito;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    background-color:  #FFFFFF;
                }
            }
        }

        &__phone-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #ffffff;
            border-radius: 8px;

            &__error {
                border: 1px solid #FF7D00;
            }
        }

        &-input-container {
            height: 48px;

            &.input {
                margin-bottom: 0px;
            }
        }

        &-input-divider {
            height: 32px;
            width: 2px;
            background-color: $black;
            margin: 8px 0;

            &__disabled {
                background-color: #D4D7D9;
            }
        }

        &-input {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            font-family: "Nunito";
            line-height: 20px;
            color: #475061;
            border: none;
            background: #ffffff;
            border-radius: 8px;
            padding: 12px 16px;
            height: 48px;

            &.readOnly {
                color: #A2AAAD;
            }

            &__error {
                border: 1px solid #FF7D00;
            }
        }

        &__error {
            font-family: Nunito;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #FF7D00;
        }

        &-checkbox {
            position: absolute;
            opacity: 0;
            z-index: -1;

            &:checked+label>.icon>label>svg {
                transform: rotate(180deg);
            }

            &:checked~div {
                max-height: max-content;
                border-top: none !important;
            }
        }

        &__button-container {
            display: flex;
            justify-content: flex-end;
            padding: 24px 0 0 24px;
        }

        &__button {
            cursor: pointer;
            height: 40px;
            width: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 48px;
            border: none;
            padding-top: 8px;
            padding-right: 24px;
            padding-bottom: 8px;
            padding-left: 24px;
            gap: 10px;
            background: #0BBEF0 !important;
            color: #ffffff;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;

            &__disabled {
                cursor: not-allowed;
                opacity: 30%;
            }

            &:hover {
                cursor: pointer;
                -webkit-filter: brightness(80%);
                filter: brightness(80%);
            }
        }

    }

    &__country-code-list-container {
        width: 120px;

        #countryCode {
            font-size: 16px;
            font-weight: 800;
            margin: 3px 0 0 0;
        }

        div:has(> ul){
            overflow-y: hidden;
            margin-top: 8px;
        }

        ul {
            background: none;
        }

        ul li {
            color: #475061;
            font-family: "Nunito";
            font-size: 16px;
            font-weight: 700;
            border: 0.5px solid #D4D7D9;
        }

        ul li:nth-of-type(1) {
            border-radius: 10px 10px 0 0;
        }

        ul li:hover {
            background-color: #F2F2F3;
        }

    }

}