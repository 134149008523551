@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.footer {
  background-color: $white;
  &__container {
    padding: 40px 20px 20px 20px;
  }
  &__items {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 29px;
    float: left;
    a {
      text-decoration: none;
    }
  }
  &__title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 15px;
    font-family: "Nunito", sans-serif;
    &--grability {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 12px;
      line-height: 8px;
      font-family: "Nunito", sans-serif;
      display: inline-block;
      padding: 0 20px;
      @media screen and (max-width: $smartphone) {
        padding: 0;
        margin-right: 5px;
      }
    }
  }
  &__grability {
    padding: 15px 0;
    text-align: center;
    border-top: 1px solid rgba(11, 190, 240, 0.3);
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: $smartphone) {
      flex-direction: column;
    }
  }
  &__social {
    margin: 0px 55px 0 0;
  }
  &__img-footer {
    padding: 0 20px;
    @media screen and (max-width: $smartphone) {
      max-height: 30px;
      object-fit: contain;
      object-position: left;
    }
    &--gri {
      padding: 0 40px !important;
    }
  }
  &__bvc-img {
    width: 62px;
    height: 38px;
  }
  &__bvc {
    max-width: 100px;
    @media screen and (max-width: $smartphone) {
      max-width: 120px;
    }
  }
  &__support {
    margin-bottom: 20px;
    @media screen and (max-width: $smartphone) {
      max-width: 250px;
      margin-bottom: 20px;
    }
    &__bid-lab-container {
      margin-top: 0 !important;
      @media screen and (max-width: $smartphone) {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
      }
    }
    &--bid {
      margin-bottom: 20px;
      max-width: 150px;
      @media screen and (max-width: $smartphone) {
        max-width: 200px;
        margin-bottom: 20px;
      }
    }
    &--lab {
      margin-bottom: 20px;
      max-width: 150px;
      @media screen and (max-width: $smartphone) {
        max-width: 130px;
        margin-bottom: 20px;
      }
    }
    &--particular {
      width: 200px !important;
      margin-left: -100px;
      @media screen and (max-width: $smartphone) {
        margin-left: 0;
      }
    }
    &--impulsa {
      width: 200px !important;
      margin-left: -40px;
      @media screen and (max-width: $smartphone) {
        margin-left: 30px !important;
      }
    }
    &--overwrite-margin {
      margin-left: -110px !important;
      @media screen and (max-width: $smartphone) {
        margin-left: 0;
      }
    }
  }
  &__branding {
    padding: 13px;
    @media screen and (max-width: $smartphone) {
      display: flex;
      padding: 0;
    }
  }
  &__information {
    max-width: 90%;
    @media screen and (max-width: $smartphone) {
      max-width: 70%;
      text-align: center;
    }
  }
  &__information--items {
    font-size: 14px;
    line-height: 16px;
    padding: 20px 40px 20px 0;
    @media screen and (max-width: $smartphone) {
      text-align: left;
    }
  }
  &__img--grability {
    width: 60px;
    margin-bottom: -3.5px;
    @media screen and (max-width: $smartphone) {
      margin-top: -6px;
      margin-bottom: 0;
    }
  }
  &__content {
    margin: auto;
  }
  &__alliance {
    border-top: 1px solid rgba(11, 190, 240, 0.3);
    padding-top: 10px;
    max-width: 100%;
    @media screen and (max-width: $smartphone) {
      max-width: none;
    }
  }
  .information {
    font-size: 10px;
    padding: 0 11px;
  }
  &__sectionOne {
    border-left: 1px solid rgba(11, 190, 240, 0.3);
    padding-top: 30px;
    @media screen and (max-width: $smartphone) {
      padding-top: 20px;
    }
    @media screen and (min-width: $smartphone) and (max-width: $tablet) {
      border: 0;
      padding: 0 49px;
    }
  }
  &__product {
    font-size: 12px !important;
  }
  &__bvclogo {
    right: 51px;
    top: 0;
    @media screen and (max-width: $smartphone) {
      padding: 10px;
    }
  }
  .social {
    @media screen and (max-width: $smartphone) {
      width: 100%;
      padding: 0 15px;
    }
  }
  &__img-a2censo {
    width: 110px;
    height: 21px;
    @media screen and (max-width: $smartphone) {
      width: 137px;
      height: 27px;
    }
  }
  .slider {
    max-width: 100% !important;
    height: auto;
    @media screen and (max-width: $smartphone) {
      max-width: 400px !important;
    }
  }
  &__img--logos {
    max-width: 80px;
  }
  &__img--logo {
    max-width: auto;
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
  &__coontainer--logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: $smartphone) {
      margin-bottom: 31px;
      flex-direction: column;
      align-items: inherit;
    }
  }
  &__vigilado {
    width: 155px;
    height: 15px;
    @media screen and (max-width: $smartphone) {
      width: 186px;
    height: 18px;
    }
  }
  &__link-no-decoration {
    text-decoration: none;
    color: black;
  }
  &__provisional-styles {
    max-width: 200px;
    margin-top: 20px;

    &--innpulsa {
      margin-top: 10px !important;
    }

    @media screen and (max-width: $smartphone) {
      max-width: 100px;
    }
  }
}
.slider .slick-slide:not(.slick-center) {
  filter: none !important;
}
.slide {
  @media screen and (max-width: $smartphone) {
    max-width: 356px;
  }
}

.slider {
  &--overwrite {
    height: 100px !important;
  }
}

.fng-size {
  max-height: 65px;
}

.footer-button{
  @media screen and (min-width: 769px){
    width: 208px !important; 
    height: 40px !important;
    padding: 8px 24px 8px 24px !important ;
    line-height : 20px !important;
    margin: 0 20px 0 0 !important;
  }
  @media screen and (min-width: 375px){
    width: 208px !important; 
    height: 40px !important;
    padding: 8px 24px 8px 24px !important ;
    line-height : 20px !important;
  }
}