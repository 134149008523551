@import "../../../styles/breakpoints.scss";
@import "../../../styles/colors.scss";

.debtColorTab {
  color: $colordebtDocument;
}
.shareColorTab {
  color: $coloraction;
}
.noteColorTab {
  color: $colornote;
}

.presentation-tab {
  &__container {
    width: 100%;
    padding: 16px 28px 32px 28px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(220px, 1fr));
    row-gap: 16px;
    column-gap: 30px;
    border-radius: 4px;

    @media screen and (max-width: $smartphone) {
      display: flex;
      flex-direction: column;
    }

    .time {
      @media screen and (max-width: $smartphone) {
        max-width: 188px;
      }
      position: relative;
      max-width: 240.25px;
      min-width: 200px;
    }
  }
  &__card {
    height: 100%;
    display: flex;
    justify-content: center;
    background: white;
    width: 100%;
    padding: 0x;
    position: relative;
    @media screen and (max-width: $smartphone) {
      padding: 0px;
      min-height: 268px;
    }
  }

  .display-more-info {
    background: #475061;
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 240.25px;
    height: 100%;
    opacity: 0.98;
    flex-direction: column;
    padding: 0px;

    @media screen and (max-width: $smartphone) {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &__close-button {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 11px;
      right: 11px;
      background-repeat: no-repeat;
      background-image: url("../../../assets/images/Close.svg");
      background-size: 18;
      background-size: 24px 24px;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;

    .info-button-container {
      cursor: pointer;
      margin: 26px auto 17px;
      display: flex;
      flex-direction: row;
      width: 145px;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__name {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #5d5a6a;
    width: 80%;
    margin: 0 auto;
  }

  &__position {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin: 0px 8px;
  }

  &__view-info {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .active {
    color: #475061;
  }

  .inactive {
    color: #DFDFDF;
  }

  &__icon {
    margin-top: 3px;
  }

  &__description {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    margin: 35px 22px 16px;
    overflow: auto;
  }
}
