@import "../../../styles/breakpoints.scss";

.bullet-detail-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 28px 32px 28px;
  width: 1107px;

  background: #f2f2f2;

  @media screen and (max-width: $responsive-desktop) {
    width: 100%;
    padding: 16px;
  }

  .bullet-detail-title-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 32px;
    row-gap: 16px;

    @media screen and (max-width: $responsive-desktop) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .bullet-detail-title-item {
      background: #f2f2f2;
      border-radius: 4px;
      &:only-detail-title-child {
        width: 1051px;
        @media screen and (max-width: $responsive-desktop) {
          width: 288px;
        }
      }

      .bullet-detail-title-list {
        list-style: none;
        counter-reset: counter;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        width: 100%;
      }
      .bullet-detail-title-content-container {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        .bullet-detail-title-number-container {
          display: flex;
          justify-content: top;
          padding-right: 20px;
          .bullet-detail-title-number {
            width: 56;
            font-family: Nunito;
            font-style: normal;
            font-weight: 900;
            font-size: 40px;
            line-height: 100%;
            color: #5d5a6a;
          }
        }
        .bullet-detail-title-content {
          // width: 438px;
          font-family: Nunito;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #5d5a6a;

          @media screen and (max-width: $responsive-desktop) {
            width: 100%;
          }
        }
      }
    }
  }
}
