@import "../../../styles/breakpoints.scss";

.bullet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background: #f2f2f2;

  .bullet-grid-container {
    width: 100%;
    max-width: 1110px;
    .bullet-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
      column-gap: 31px;
      padding: 16px 28px 32px;
      @media screen and (max-width: $smartphone) {
        display: flex;
        flex-direction: column;
        row-gap: 0px;
      }
      .bullet-item {
        background: #ffffff;
        border-radius: 4px;
        @media screen and (max-width: $smartphone) {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
        &:only-child {
          width: 100%;
          @media screen and (max-width: $smartphone) {
            width: 100%;
          }
        }
  
        .bullet-list {
          list-style: none;
          counter-reset: counter;
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          width: 100%;
        }
        .bullet-content-container {
          display: flex;
          padding: 16px;
          .bullet-number-container{
            display: flex;
            justify-content: center;
            padding-right: 20px; 
             .bullet-number {
               width: 56;
               font-family: Nunito;
               font-style: normal;
               font-weight: 900;
               font-size: 32px;
               line-height: 40px;
               color: #5d5a6a;
             }
          }
          .bullet-content {
            // width: 438px;
            font-family: Nunito;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 162%;
            color: #5D5A6A;
  
            @media screen and (max-width: $smartphone) {
              width: 216px;
            }
          }
        }
  
      }
    }
  }
}
