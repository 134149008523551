@import "../../../styles/breakpoints.scss";
.text_image_container {
  padding: 16px 28px 32px;

  @media screen and (max-width: $smartphone) {
    padding: 16px;
  }

  .group_container {
    background: #FFFFFF;
    border-radius: 8px;
  }

  .image_container {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(410px, 1fr));
    column-gap: 0px !important;
    row-gap: 0px !important;
    align-items: end;
    @media screen and (max-width: $tablet) {
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas: none;
      column-gap: 16px;
    }
    .image_content_container {
      width: 100%;
      display: block;
      justify-content: center;
      text-align: center;
      .image_graphics {
        width: 100%;
        max-width: 400px;
        height: 228px;
      }
      .title {
        text-align: center;
        font-family: Nunito;
        font-size: 14px;
        line-height: 19px;
        font-style: normal;
        font-weight: bold;
        margin: 38px auto 11px;
        @media screen and (max-width: $smartphone) {
          margin: 16px auto 16px;
        }
      }
    }
  }
}
