$bright-turquoise: #0bbbef;
$bright-turquoise-transparent: #b7e4f5;
$congress-blue: #004997;
$tuatara: #31302f;
$spring-green: #39fbbf;
$cornflower-blue: #6262f9;
$transparent-gray: rgba(200, 200, 201, 0.2);
$transparent-white: rgba(255, 255, 255, 0.6);
$middle-gray: rgb(166, 166, 169);
$gray: #f5f6f8;
$description-gray: #6d7278;
$dark-gray: #babdca;
$line-gray: #cfd1da;
$spanish-gray: #9b9b9b;
$white: #ffffff;
$bright-blue: #ddf1f8;
$red: #e51c32;
$congress-turquoise: #0bbef0;
$dark-blue: #004997;
$light-gray: rgba(219, 220, 221, 0.44);
$black: #000;
$light-line-gray: rgba(200, 200, 201, 0.39);
$royal-blue-linear-gradient: linear-gradient(90deg, #4583e0 0%, #455fe0 82.29%);
$royal-blue: #4568e0;
$colordebt: #04a3ae;
$colordebtDocument:#02bac6;
$coloraction: #7d7bfa;
$colornote :#496cff;
$font-purple-gray: #5D5A6A;
$transparent: transparent;
$mako: #475061;
$light-gray-hex: #E6E6E6;
$amber: #FF7D00;